import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'materialize-css/dist/css/materialize.css'
import 'materialize-css'
import 'nprogress/nprogress.css'
import firebase from 'firebase/app'
import { i18n } from './i18n'
import Fragment from 'vue-fragment'

Vue.use(Fragment.Plugin)
Vue.config.productionTip = false

var config = {
  apiKey: process.env.VUE_APP_fb_apiKey,
  authDomain: process.env.VUE_APP_fb_authDomain,
  databaseURL: process.env.VUE_APP_fb_databaseURL,
  projectId: process.env.VUE_APP_fb_projectId,
  storageBucket: process.env.VUE_APP_fb_storageBucket,
  messagingSenderId: process.env.VUE_APP_fb_messagingSenderId
};
firebase.initializeApp(config);

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  i18n: i18n,
  render: h => h(App)
}).$mount('#app')