<template>
    <div>
        <!--
         -{{ isBannerFullyDefined }}= -{{ progressStep }}= -ltp:
        {{ isBannerDataValid }}= -->
        <!-- -{{ isBannerDataValid }}= -{{ banner }}= -->

        <div class="container">
            <h2>Banery</h2>
            <div>
                <a href @click.prevent="modal.open()">nowy baner</a>
            </div>
            <div v-for="section in banners" :key="section.section">
                <h3 v-if="banners.lenght > 1">{{ section.section }}</h3>

                <transition-group name="flip-list" tag="div">
                    <div
                            v-for="(banner, index) in section.banners"
                            :key="banner.id"
                            class="card-panel row"
                    >
                        <h5>{{ banner.name }}</h5>
                        <a :href="banner.linkTo.raw" target="_new">
                            <img
                                    :src="banner.image.desktop"
                                    :alt="banner.alt"
                                    :title="banner.alt"
                                    class="col s5"
                            /></a>
                        <div class="col s4">
                            {{ $d(new Date(banner.createDate.seconds * 1000), "medium") }}
                            <br/>
<!--                            <button @click="resize(banner)">rsz</button>-->
                            <a
                                    href
                                    class="btn-tiny"
                                    @click.prevent="
                  modalConfirmDelete.instance.open();
                  modalConfirmDelete.bannerName = banner.name;
                  modalConfirmDelete.bannerId = banner.id;
                  modalConfirmDelete.section = section.section;
                "
                            ><i class="material-icons">delete</i></a
                            >
                            <a
                                    href
                                    v-if="index > 0"
                                    class="btn-tiny"
                                    @click.prevent="move(section.section, banner.id, 'up')"
                            ><i class="material-icons">keyboard_arrow_up</i></a
                            >
                            <a
                                    href
                                    v-if="index < section.banners.length - 1"
                                    class="btn-tiny"
                                    @click.prevent="move(section.section, banner.id, 'down')"
                            ><i class="material-icons">keyboard_arrow_down</i></a
                            >
                        </div>
                    </div>
                </transition-group>
            </div>

            <div id="newBannerModal" class="modal modal-fixed-footer">
                <div class="modal-content">
                    <h3>Nowy Baner</h3>
                    <form v-on:submit.prevent class="">
                        <div class="row">
                            <div class="input-field col s7">
                                <input
                                        id="baner_name"
                                        type="text"
                                        class="validate"
                                        v-model="banner.name"
                                />
                                <label for="baner_name"
                                >nazwa banera - nigdzie nie pokazywana na stronie</label
                                >
                            </div>
                        </div>

                        <div class="file-field input-field row">
                            <div
                                    class="btn-small col s3"
                                    v-bind:class="{ disabled: uploadInProgress }"
                            >
                                <span>Grafika desktop</span>
                                <input
                                        type="file"
                                        id="file"
                                        ref="file"
                                        v-on:change="handleFileUpload($event.target.files, 'home', 'desktop');"
                                />
                            </div>

                            <div class="file-path-wrapper col s4">
                                <input class="file-path validate" type="text"/>
                            </div>

                            <img :src="banner.image_desktop" class="col s5"/>
                        </div>

                        <div class="file-field input-field row">
                            <div
                                    class="btn col s3"
                                    v-bind:class="{ disabled: uploadInProgress }"
                            >
                                <span>Grafika mobile</span>
                                <input
                                        type="file"
                                        id="file"
                                        ref="file"
                                        v-on:change="
                    handleFileUpload($event.target.files, 'home', 'mobile')
                  "
                                />
                            </div>

                            <div class="file-path-wrapper col s4">
                                <input class="file-path validate" type="text"/>
                            </div>

                            <img :src="banner.image_mobile" class="col s5"/>
                        </div>

                        <div class="row">
                            <div class="input-field col s12">
                                <input
                                        id="baner_url"
                                        type="text"
                                        class="validate"
                                        v-model="banner.linkToRaw"
                                />
                                <label for="baner_url">Pełny url, gdzie prowadzi baner</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="input-field col s12">
                                <input
                                        id="baner_alt"
                                        type="text"
                                        class="validate"
                                        v-model="banner.alt"
                                />
                                <label for="baner_alt">tekst zapasowy do ALT</label>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <a
                            class="waves-effect waves-teal btn"
                            :class="{ disabled: !isBannerDataValid }"
                            @click="handleSave()"
                    ><i class="material-icons left">save</i>Zapisz</a
                    >

                    <a class="waves-effect waves-teal btn-flat" @click="modal.close()"
                    >Anuluj</a
                    >
                </div>
            </div>

            <div id="modalConfirmRemove" class="modal">
                <div class="modal-content">
                    <h4>Usunąć baner?</h4>
                    <p>
                        {{ modalConfirmDelete.bannerName }} ({{
                        modalConfirmDelete.section
                        }})
                    </p>
                </div>
                <div class="modal-footer">
                    <a class="waves-effect waves-teal btn red" @click="handleDelete"
                    ><i class="material-icons left">delete</i>Usuń</a
                    >

                    <a
                            class="waves-effect waves-teal btn-flat"
                            @click="modalConfirmDelete.instance.close()"
                    >Anuluj</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/storage";

import admin from "@/admin";
import axios from "axios";

export default {
    components: {},

    data() {
        return {
            uploadInProgress: false,

            banners: [],
            bannersListenerUnsubscribe: null,

            banner: this.getEmptyBanner(),

            env: process.env,
            modal: null,
            modalConfirmDelete: {
                instance: null,
                bannerId: null,
                bannerName: null,
                section: null,
            },
        };
    },

    computed: {
        isBannerDataValid() {
            return (
                this.banner.image_desktop != "" &&
                this.banner.image_mobile != "" &&
                this.banner.name != "" &&
                this.banner.alt != "" &&
                this.banner.linkTo != null
            );
        },
    },

    watch: {
        "banner.linkToRaw": function (newVal, oldVal) {
            const parsed = this.parseLinkTo(newVal);
            console.log("n", newVal, parsed);
            if (parsed) this.banner.linkTo = parsed;
            else this.banner.linkTo = null;
        },
    },

    methods: {
        getEmptyBanner() {
            return {
                name: "",
                image_mobile: "",
                image_desktop: "",
                linkTo: null,
                linkToRaw: null,
                alt: "",
            };
        },

        parseLinkTo(linkTo) {
            if (!linkTo) return;

            //https://<HOST>/oferta/GLENFIDDICH-21Y,a64_b10
            console.log(linkTo);
            let chunks = linkTo.replace("https://", "").split("/");

            if (chunks.length != 3) return;

            let viewName;
            if (chunks[1] == "oferta") viewName = "browse";
            else return;

            return {view: viewName, value: chunks[2], raw: linkTo};
        },

        handleFileUpload: function (fileList, areaId, imageType) {
            if (!fileList.length) return;

            console.log("banerFile", fileList[0]);

            this.uploadInProgress = true;
            const bannerFile = fileList[0];
            let ref = firebase
                .storage()
                .ref()
                .child(`banners_${areaId}_${imageType}-` + bannerFile.name);

            ref.put(bannerFile).then((snapshot) => {
                console.log("Uploaded a blob or file!", snapshot);
                snapshot.ref
                    .getDownloadURL()
                    .then((u) =>
                        imageType == "desktop"
                            ? (this.banner.image_desktop = u)
                            : (this.banner.image_mobile = u)
                    );
                this.uploadInProgress = false;

            });
        },

        handleSave: function () {
            const bannerId = new Date().getTime()
            firebase
                .firestore()
                .collection("banners")
                .doc("home") //todo: - wsparcie dla sekcji
                .update({
                    banners: firebase.firestore.FieldValue.arrayUnion({
                        id: bannerId,
                        alt: this.banner.alt,
                        name: this.banner.name,
                        image: {
                            desktop: this.banner.image_desktop,
                            mobile: this.banner.image_mobile,
                        },
                        linkTo: this.banner.linkTo,
                        createDate: firebase.firestore.Timestamp.now(),
                    }),
                })
                .then((res) => {
                    console.log("save success", bannerId);
                    this.resize({id: bannerId, image: {mobile: this.banner.image_mobile}})

                    admin.toastInfo("Baner zapisany: " + this.banner.name);
                    this.banner = this.getEmptyBanner();
                    this.modal.close();
                });
        },

        handleDelete: async function () {
            console.log(
                "handleDelete",
                this.modalConfirmDelete.bannerId,
                this.modalConfirmDelete.section
            );

            //retireve the full baner payload from FB
            const bannersDoc = await firebase
                .firestore()
                .collection("banners")
                .doc(this.modalConfirmDelete.section)
                .get();

            const bannerInstance = bannersDoc
                .data()
                .banners.find((b) => b.id == this.modalConfirmDelete.bannerId);

            console.log("bd", bannerInstance);
            if (!bannerInstance) {
                this.$emit("error", "BANER.1");
                return;
            }

            firebase
                .firestore()
                .collection("banners")
                .doc(this.modalConfirmDelete.section)
                .update({
                    banners: firebase.firestore.FieldValue.arrayRemove(bannerInstance),
                })
                .then((res) => {
                    console.log("delete success", bannerInstance.id);

                    admin.toastInfo("Skasowano: " + bannerInstance.name);
                    this.modalConfirmDelete.instance.close();
                });
        },

        move: function (sectionName, bannerId, direction) {
            const section = this.banners.find(
                (section) => section.section == sectionName
            );
            const banners = section.banners;
            // const banners=[{id:'a'},{id:'b'},{id:'c'},{id:'d'},{id:'e'}]
            // console.log("before ", banners)

            const index = banners.findIndex((b) => b.id == bannerId);

            var resultant;
            if (direction === "up") {
                const left = banners.slice(0, index);
                const right = banners.slice(index);
                const leftLast = left.pop();
                left.push(right.shift());
                left.push(leftLast);

                resultant = left.concat(right);
            } else {
                const left = banners.slice(0, index + 1);
                const right = banners.slice(index + 1);

                const lifted = right.shift();
                right.unshift(left.pop());
                right.unshift(lifted);
                resultant = left.concat(right);
            }

            firebase
                .firestore()
                .collection("banners")
                .doc("home")
                .set({banners: resultant}, {merge: true});
        },

        resize: async function (banner) {
            console.log("resize", banner)

            const resizeCallable = firebase
                .functions()
                .httpsCallable("resizeBanner");
            resizeCallable(
                {
                    srcImageUrl: banner.image.mobile,
                    bannerId: banner.id
                }
            ).then(res => {
                console.log('resized ' + res.data)
            })
        }
    },

    created() {
        const supportedAreas = ["home"];
        console.log("created");

        this.bannersListenerUnsubscribe = firebase
            .firestore()
            .collection("banners")
            .where(firebase.firestore.FieldPath.documentId(), "in", supportedAreas)
            .onSnapshot((querySnapshot) => {
                // this.banners = querySnapshot.docs;
                console.log("aaa", querySnapshot.docs);
                this.banners = querySnapshot.docs.map((d) => {
                    return {
                        section: d.id,
                        banners: d.data().banners
                    };
                });
            });
    },

    mounted() {
        M.Modal.init(document.querySelectorAll("#newBannerModal"), {
            onCloseEnd: () => {
                console.log("modal on close end");
            },
        });
        M.Modal.init(document.querySelectorAll("#modalConfirmRemove"), {
            onCloseEnd: () => {
                console.log("modalConfirmRemove on close end");
            },
        });

        this.modal = M.Modal.getInstance(document.querySelector("#newBannerModal"));
        this.modalConfirmDelete.instance = M.Modal.getInstance(
            document.querySelector("#modalConfirmRemove")
        );
    },

    beforeDestroy() {
        this.bannersListenerUnsubscribe();
    },
};
</script>

<style scoped>
.flip-list-move {
    transition: transform 1s;
    background: lightgray;
}
</style>
