// http://kazupon.github.io/vue-i18n/guide/started.html#javascript

import VueI18n from 'vue-i18n'
import Vue from 'vue';

Vue.use(VueI18n)

const numberFormats = {
    'en-US': {
        currency: {
            style: 'currency', currency: 'USD'
        }
    },
    'pl-PL': {
        currency: {
            style: 'currency', currency: 'PLN', currencyDisplay: 'symbol'
        }
    }
}

const messages = {
    'pl-PL': {
        'orderStatus': {
            "UNPAID": "Oczekuje na płatność",
            "PAID": "Zapłacone",
            "CANCELLED": "Anulowane",
            "DISPATCHED": "Wysłane"
        },
        'uploadProducts': {
            noItem: "Nie znaleziono produktu z id: {0}",
            unrecognizedFilename: 'Nieznany plik: {0}',
            invalidJPK_missingID: 'Linia {0}: Nie znaleziono kolumny ID. Kolumna ID jest wymagana.',
            invalidJPK_itemIdWithInvalidChar: 'Linia {3}: ID produktu zawiera nieobsługiwany znak. Obsługiwane są znaki z zakresu: {2} Produkt: {0} {1}.',
            invalidJPK_itemIdWithForbiddenChar: 'Linia {3}: ID produktu zawiera zabroniony znak. Zabronione znaki: {2} Produkt: {0} {1}.',
            invalidJPK_missingName: 'Linia {1}: Produkt bez tytułu. Tytuł jest wymagany. ID produktu: {0}',
            invalidJPK_missingCategories: 'Linia {2}: Produkt nie ma przypisanej żadnej kategorii. Conajmniej jedna kategoria jest wymagana. Produkt: {0} {1}.',
            invalidJPK_duplicatedId: 'Linia {1}: Powtórzone ID produktu {0}. ID musi być unikalne.',
            invalidJPK_bundleParsingException_unknownItem: 'Zestaw {1} zawiera nieznany produkt: {0}.',
            invalidJPK_bundleParsingException_missingBundleDefinition: 'W produkcie {0} brakuje informacji o zestawie.',
            invalidJPK_bundleParsingException_noBundleString: 'Produkt {0}: Brak informacji o zestawie.',
            invalidJPK_bundleParsingException_invalidValue: ' Produkt {1}. Nieprawidłowa konfiguracja zestawu: {0}.'
        },
        'orderUpdateAsPaid': {
            'no-doc': "Nie znaleziono zamówienia."
        },
        monthNamesByNumber: {
            1: "Styczeń",
            2: "Luty",
            3: "Marzec",
            4: "Kwiecień",
            5: "Maj",
            6: "Czerwiec",
            7: "Lipiec",
            8: "Sierpień",
            9: "Wrzesień",
            10: "Październik",
            11: 'Listopad',
            12: 'Grudzień',
        }
    }
}

const dateTimeFormats = {
    'pl-PL': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        shortDt: {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit'
        },
        shortDate: {
            year: '2-digit', month: '2-digit', day: '2-digit'
        },       
        medium: {
            year: 'numeric', month: 'long', day: 'numeric',
            hour: '2-digit', minute: '2-digit'
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric',
            weekday: 'long', hour: 'numeric', minute: 'numeric'
        }
    }
}

export const i18n = new VueI18n({
    locale: 'pl-PL', // set locale
    numberFormats,
    dateTimeFormats,
    messages
})

