<template>
  <div class="container">
    <h1>Historia sprzedaży</h1>
    <h4>{{ initialItemIdRo }}</h4>
    <div class="row">
      <div class="input-field col s12">
        <i class="material-icons prefix">search</i>
        <input type="text" id="autocomplete-input" class="autocomplete" />
        <label for="autocomplete-input">szukaj po nazwie lub id produktu</label>
      </div>
    </div>
    <div class="row">
      <div class="col s12 l8">
        Okres:
        <br />
        <label>
          <input
            name="dateRange"
            type="radio"
            value="all"
            v-model="dateRange"
            checked
          />
          <span style="padding-right: 20px">Wszystko</span>
        </label>

        <label>
          <input
            name="dateRange"
            type="radio"
            value="thisMonth"
            v-model="dateRange"
          />
          <span style="padding-right: 20px">Ten miesiąc</span>
        </label>

        <label>
          <input
            name="dateRange"
            type="radio"
            value="previousMonth"
            v-model="dateRange"
          />
          <span style="padding-right: 20px">Poprzedni miesiąc</span>
        </label>
      </div>
      <div class="col s12 m6 l2">
        <input
          type="text"
          class="datepicker"
          name="dateRangeFrom"
          id="dateRangeFrom"
        />
        <label for="dateRangeFrom">Data od</label>
      </div>
      <div class="col s12 m6 l2">
        <input
          type="text"
          class="datepicker"
          name="dateRangeTo"
          id="dateRangeTo"
        />
        <label for="dateRangeTo">Data do</label>
      </div>
    </div>
    <div class="card">
      <canvas id="myChart"></canvas>
    </div>
    <div class="card">
      <table
        id="ordersTable"
        class="row-border hover order-column"
        cellspacing="0"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th v-for="index in 3" :key="index">C{{ index }}</th>
          </tr>
        </thead>
      </table>
    </div>

    <!-- pola do przekazywania wartosci z vue do callbackow tabeli-->
    <span id="dateRange"></span>
    <span id="dateRangeFrom"></span>
    <span id="dateRangeTo"></span>
    <span id="itemId"></span>

    <!--  modal produktu -->
    <item-quick-view></item-quick-view>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import firebase from 'firebase/app'
import ItemQuickView from "@/components/ItemQuickView.vue";
import M from "materialize-css";
import Chart from "chart.js";
import axios from "axios";

export default {
  props: {
    initialItemId: String,
  },

  components: {
    itemQuickView: ItemQuickView,
  },

  data() {
    return {
      itemName: null,
      itemId: null,
      datePickerInstances: null,
      dateRange: "all",
      dateRangeFrom: null,
      dateRangeTo: null,
      initialItemIdRo: null,
    };
  },

  watch: {
    dateRange: function (after, before) {
      console.log("dr", after);
      $("#dateRange").val(after); // przekazanie wartosci do scope widzianego przez jQuery
      if (after) {
        this.dateRangeFrom = null;
        this.dateRangeTo = null;
        $("#dateRangeFrom").val(null);
        $("#dateRangeTo").val(null);
        this.datePickerInstances[0].el.value = null;
        this.datePickerInstances[1].el.value = null;
        this.doInit();
        this.fetchReportData();
      }
    },
    dateRangeFrom: function (after, before) {
      if (after) {
        $("#dateRangeFrom").val(after.toISOString().substring(0, 10)); // przekazanie wartosci do scope widzianego przez jQuery
        if (this.dateRangeTo) {
          $("#dateRange").val(null);
          this.dateRange = null;
        }
        if (this.dateRangeTo && this.dateRangeFrom) {
          this.doInit();
          this.fetchReportData();
        }
      }
    },
    dateRangeTo: function (after, before) {
      if (after) {
        $("#dateRangeTo").val(after.toISOString().substring(0, 10)); // przekazanie wartosci do scope widzianego przez jQuery
        if (this.dateRangeFrom) {
          $("#dateRange").val(null);
          this.dateRange = null;
        }
        if (this.dateRangeTo && this.dateRangeFrom) {
          this.doInit();
          this.fetchReportData();
        }
      }
    },
    itemId: function (after, before) {
      console.log("updated itemId:", after);
      $("#itemId").val(after);
      if (after) {
        this.doInit();
        this.fetchReportData();
      }
    },
  },

  computed: {
    initialItemIdRoXXX: {
      get: function () {
        return this.initialItemId;
      },
      set: function (newValue) {
        console.log("sss", newValue);
        //skip
      },
    },
  },

  methods: {
    doInit: function () {
      //https://datatables.net/examples/ajax/objects.html
      $("#ordersTable").DataTable().destroy();

      var table = $("#ordersTable").DataTable({
        ajax: function (data, callback, settings) {
          let orderUpdateStatusCallable = firebase
            .functions()
            .httpsCallable("bqCustomQuery");

          orderUpdateStatusCallable({
            dateRange: $("#dateRange").val(),
            dateRangeFrom: $("#dateRangeFrom").val(),
            dateRangeTo: $("#dateRangeTo").val(),
            itemId: $("#itemId").val(),
            q: "ordersByProductOverTime",
          })
            .then((result) => {
              callback({
                data: result.data,
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        },
        columns: [
          {
            title: "Data",
            data: "dateSeq",
          },
          {
            title: "Cena sprzedaży",
            data: "price",
            render: (data, type, row, meta) => {
              if (type === "display" || type === "filter")
                //https://datatables.net/manual/data/orthogonal-data
                return data ? this.$i18n.n(data, "currency") : data;
              else return data;
            },
            className: "dt-body-right",
          },
          {
            title: "Wartość",
            data: "ordersTotal",
            render: (data, type, row, meta) => {
              if (type === "display" || type === "filter")
                //https://datatables.net/manual/data/orthogonal-data
                return data ? this.$i18n.n(data, "currency") : data;
              else return data;
            },
            className: "dt-body-right",
          },
        ],

        language: {
          search: "",
          searchPlaceholder: "Szukaj po dacie, kwocie itp...",
          info: "Pokazywane wiersze _START_ - _END_ z _TOTAL_",
        },
        order: [0, "desc"],

        dom: 'ft<"footer-wrapper"l<"paging-info"ip>B>',
        scrollY: "35vh",
        // scrollCollapse: true,
        pagingType: "full",
        paging: false,
        buttons: ["copy", "csv", "excel", "pdf", "print"],
        drawCallback: function (settings) {
          var api = this.api();

          // Add waves to pagination buttons
          $(api.table().container()).find(".paginate_button");

          api.table().columns.adjust();
        },
      });
    },

    initializeChart: () => {
      var ctx = document.getElementById("myChart").getContext("2d");
      var chart = new Chart(ctx, {
        // The type of chart we want to create
        type: "bar",

        // The data for our dataset
        data: {
          // labels: [
          //   "January",
          //   "February",
          //   "March",
          //   "April",
          //   "May",
          //   "June",
          //   "July",
          // ],
          datasets: [
            {
              label: "Wartość sprzedaży zł",
              backgroundColor: Chart.helpers
                .color("rgb(186, 104, 200)")
                .alpha(0.5)
                .rgbString(),
              borderColor: "rgb(142, 36, 170)",
              borderWidth: 1,
              fill: true,
              lineTension: 0, //zaokraglanie - 0=kanciate
              spanGaps: true,
            },
          ],
        },

        // Configuration options go here
        options: {
          scales: {
            xAxes: [
              {
                // type: 'time',
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 10,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Sprzedaż zł",
                },
              },
            ],
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem, data) {
                return Number(tooltipItem.yLabel).toFixed(2) + " zł";
              },
            },
          },
        },
      });

      return chart;
    },

    initializeProductSearch: () => {
      return M.Autocomplete.init(document.querySelectorAll(".autocomplete"), {
        data: {},
        onAutocomplete: (val) => {
          console.log(val);
          const itemId = val
            .substring(val.lastIndexOf(" ") + 1)
            .replaceAll("(", "")
            .replaceAll(")", "");
          const itemName = val.substring(0, val.lastIndexOf(" "));
          console.log("selected", itemId);
          $("body").trigger("szukajka:ordersByProductInTime:itemSelected", [
            { itemId: itemId, itemName: itemName, rawVal: val },
          ]);
        },
      });
    },

    fetchReportData: function () {
      let orderUpdateStatusCallable = firebase
        .functions()
        .httpsCallable("bqCustomQuery");

      orderUpdateStatusCallable({
        dateRange: $("#dateRange").val(),
        dateRangeFrom: $("#dateRangeFrom").val(),
        dateRangeTo: $("#dateRangeTo").val(),
        q: "ordersByProductOverTime",
        itemId: this.itemId,
      })
        .then((result) => {
          $("body").trigger(
            "szukajka:ordersByProductInTime:reportDataUpdated",
            [{ data: result.data }]
          );
        })
        .catch((err) => {
          console.log("fetchReportData:", err);
        });
    },

    testResponse: () => {
      var aa = {
        result: [
          {
            dateSeq: "2020-05-27",
            price: 11,
            priceNormalized: 0,
            ordersTotal: 1000,
            ordersTotalNormalized: 0,
          },
          {
            dateSeq: "2020-05-28",
            price: null,
            priceNormalized: 0,
            ordersTotal: null,
            ordersTotalNormalized: 0,
          },
          {
            dateSeq: "2020-05-29",
            price: 11,
            priceNormalized: 0,
            ordersTotal: 1234,
            ordersTotalNormalized: 0,
          },
          {
            dateSeq: "2020-05-30",
            price: null,
            priceNormalized: 0,
            ordersTotal: null,
            ordersTotalNormalized: 0,
          },
          {
            dateSeq: "2020-05-31",
            price: 12,
            priceNormalized: 0,
            ordersTotal: 762,
            ordersTotalNormalized: 0,
          },
          {
            dateSeq: "2020-06-01",
            price: 5,
            priceNormalized: 0,
            ordersTotal: 854,
            ordersTotalNormalized: 0,
          },
          {
            dateSeq: "2020-06-02",
            price: null,
            priceNormalized: 0,
            ordersTotal: null,
            ordersTotalNormalized: 0,
          },
          {
            dateSeq: "2020-06-03",
            price: null,
            priceNormalized: 0,
            ordersTotal: null,
            ordersTotalNormalized: 0,
          },
          {
            dateSeq: "2020-06-04",
            price: 7,
            priceNormalized: 0,
            ordersTotal: 777,
            ordersTotalNormalized: 0,
          },
        ],
      };
    },
  },

  mounted: function () {
    this.doInit();
    var chart = this.initializeChart();

    $("body").on(
      "szukajka:ordersByProductInTime:reportDataUpdated",
      (event, payload) => {
        console.log(
          "szukajka:ordersByProductInTime:reportDataUpdated",
          payload
        );
        const labels = payload.data.map((row) => row.dateSeq);
        const ordersTotal = payload.data.map((row) => row.ordersTotal);

        chart.data.labels = labels;
        chart.data.datasets[0].data = ordersTotal;
        chart.update();
      }
    );

    this.datePickerInstances = M.Datepicker.init(
      document.querySelectorAll(".datepicker"),
      {
        autoClose: true,
        format: "yyyy-mm-dd",
        minDate: new Date("2020-01-01"),
        onSelect: function (date) {
          $("body").trigger("szukajka:ordersByProductInTime:dateFrom:clicked", [
            { date: date, source: this.el.name },
          ]);
        },
        i18n: {
          clear: "Wyczyść",
          cancel: "Anuluj",
          months: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
          ],
          monthsShort: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
          ],
          weekdays: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
          ],
          weekdaysShort: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
          ],
          weekdaysAbbrev: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
        },
      }
    );

    $("body").on(
      "szukajka:ordersByProductInTime:dateFrom:clicked",
      (event, data) => {
        if (data.source == "dateRangeFrom") this.dateRangeFrom = data.date;
        else if (data.source == "dateRangeTo") this.dateRangeTo = data.date;
      }
    );

    $("body").on(
      "szukajka:ordersByProductInTime:itemSelected",
      (event, data) => {
        console.log("on szukajka:ordersByProductInTime:itemSelected", data);
        this.itemId = data.itemId;
        this.initialItemIdRo = data.rawVal;
      }
    );

    this.itemId = this.initialItemId;
    this.initialItemIdRo = this.initialItemId;

    this.fetchReportData();
    this.initializeProductSearch();

    axios
      .get(
        process.env.VUE_APP_API +
          "/api/query/items/allItemsInJpk" +
          `?stock=all&image=1&description=all`
      )
      .then((response) => {
        console.log(response.data.items);
        var obj = response.data.items.reduce(
          (acc, curr) => ((acc[`${curr.name} (${curr.id})`] = null), acc),
          {}
        );
        M.Autocomplete.getInstance(
          document.querySelectorAll(".autocomplete")[0]
        ).updateData(obj);

        this.initialItemIdRo =
          response.data.items.filter((item) => item.id == this.initialItemId)[0]
            .name + `(${this.initialItemId})`;
      })
      .catch((error) => {
        console.log("refresh all items error: " + error);
      });

    setTimeout(() => {
      M.updateTextFields();
    }, 1000);
  },

  created: function () {},

  destroyed: function () {
    $("body").off("szukajka:ordersByProductInTime:dateFrom:clicked");
    $("body").off("szukajka:ordersByProductInTime:reportDataUpdated");
    $("body").off("szukajka:ordersByProductInTime:itemSelected");
    console.log("destroyed");
  },

  beforeRouteUpdate(to, from, next) {
    console.log(`beforeRouteUpdate: ${to.params.reportId} `);
    this.reportId = to.params.reportId;
    this.doInit();
    next();
  },
};
</script>

<style scoped>
.container {
  width: 90%;
}
</style>
