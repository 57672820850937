<template>
  <div class="container">
    <div class="masonry row equal-height-grid">
      <div class="col s12">
        <h2>Admin</h2>
      </div>
      <div class="col m4 s12" v-if="this.$store.getters.appMetrics">
        <div class="card hoverable">
          <div class="card-stacked">
            <div class="card-metrics card-metrics-static">
              <div class="card-metric">
                <div class="card-metric-title">Produkty</div>
                <div
                  class="card-metric-value"
                >bez zdjęć: {{ this.$store.getters.appMetrics['szukajka.dd.allItemsInJpkInStockWithNoImages'] }}</div>
                <div
                  class="card-metric-value"
                >bez opisów: {{ this.$store.getters.appMetrics['szukajka.dd.allItemsInJpkInStockAndNoDescription'] }}</div>
                <br />
                <router-link
                  class="right"
                  :to="{name: 'reportItems', params: {reportId: 'allItemsInJpk'}}"
                >więcej</router-link>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col m4 s12" v-if="this.$store.getters.appMetrics">
        <div class="card">
          <div class="card-stacked">
            <div class="card-metrics card-metrics-static">
              <div class="card-metric">
                <div class="card-metric-title">Produkty - dostępność</div>
                <div
                  class="card-metric-value"
                >dostępne: {{ this.$store.getters.appMetrics['szukajka.searchIndex.inStock.size'] }}</div>
                <div
                  class="card-metric-value"
                >niedostępne: {{ this.$store.getters.appMetrics['szukajka.searchIndex.ooStock.size'] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col m4 s12" v-if="this.$store.getters.appMetrics">
        <div class="card">
          <div class="card-stacked">
            <div class="card-metrics card-metrics-static">
              <div class="card-metric">
                <div class="card-metric-title">Stany - ostatnia aktualizacja</div>
                <div
                  class="card-metric-value"
                >{{ $d(new Date(this.$store.getters.appMetrics['szukajka.stockRepo.lastUpdated']), 'medium') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12" v-if="lastOrder">
        <div class="card hoverable">
          <div class="card-stacked">
            <div class="card-metrics card-metrics-static">
              <div class="card-metric">
                <div
                  class="right"
                >{{ $d(new Date(lastOrder.data.createDate.seconds*1000), 'long') }}</div>
                <div class="card-metric-title">Ostatnie zamówienie</div>
                <div class="right">{{lastOrder.data.delivery.name}}</div>
                <div class="card-metric-value">{{lastOrder.data.orderNumber}}</div>
                <div class="right">{{$t('orderStatus.'+lastOrder.data.status)}}</div>
                <p>{{ $n(lastOrder.data.totalOrderCost, 'currency') }}</p>
                <span>
                  {{lastOrder.data.deliveryAddress.name}},&nbsp;
                  {{lastOrder.data.deliveryAddress.street}},&nbsp;
                  {{lastOrder.data.deliveryAddress.city}}
                </span>
                <br />
                <router-link
                  class="right"
                  :to="{name: 'order', params: {orderId: lastOrder.id}}"
                >więcej</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import "firebase/firestore";

export default {
  name: "home",
  data() {
    return {
      lastOrder: null
    };
  },
  components: {},
  methods: {
    fetchLastOrder() {
      console.log("fetchLastOrder...");
      firebase
        .firestore()
        .collection("orders")
        .orderBy("createDate", "desc")
        .limit(1)
        .onSnapshot(querySnapshot => {
          console.log("on snapshot");
          querySnapshot.forEach(doc => {
            this.lastOrder = { id: doc.id, data: doc.data() };
          });
        });
    }
  },

  created() {
    console.log("created,...");
    // this.user = firebase.auth().currentUser;
    this.fetchLastOrder();
  }
};
</script>
