<template>
    <fragment>
        <header>
            <div class="navbar-fixed">
                <nav class="navbar white">
                    <div class="nav-wrapper">
                        <ul id="nav-mobile" class="right">
                            <li class="hide-on-med-and-down">
                                <a href data-target="dropdown1" class="dropdown-trigger">
                                    <!-- <i class="material-icons">notifications</i> -->
                                </a>
                            </li>
                        </ul>
                        <a href data-target="sidenav-left" class="sidenav-trigger left">
                            <i class="material-icons black-text">menu</i>
                        </a>
                    </div>
                </nav>
            </div>
            <ul id="sidenav-left" class="sidenav">
                <li>
                    <router-link
                            :to="{ name: 'home' }"
                            class="sidenav-close logo-container"
                    >
                        Admin
                        <i class="material-icons left">home</i>
                    </router-link>
                </li>

                <li>
                    <router-link :to="{ name: 'orders' }" class="sidenav-close">
                        Zamówienia
                        <i class="material-icons">shopping_cart</i>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'upload' }" class="sidenav-close">
                        Oferta
                        <i class="material-icons">cloud_upload</i>
                    </router-link>
                </li>
                <li>
                    <router-link
                            :to="{
              name: 'reportItems',
              params: { reportId: 'allItemsInJpk' },
            }"
                            class="sidenav-close"
                    >
                        Produkty
                        <i class="material-icons">grid_on</i>
                    </router-link>
                </li>

                <li
                        v-if="
            this.$store.getters.userClaims &&
            this.$store.getters.userClaims.includes('reviews')
          "
                >
                    <router-link
                            :to="{
              name: 'banners',
            }"
                            class="sidenav-close"
                    >
                        Banery
                        <i class="material-icons">perm_media</i>
                    </router-link>
                </li>
                <li
                        v-if="
            this.$store.getters.userClaims &&
            this.$store.getters.userClaims.includes('reviews')
          "
                >
                    <router-link
                            :to="{
              name: 'reviews',
            }"
                            class="sidenav-close"
                    >
                        Opinie
                        <i class="material-icons">comment</i>
                    </router-link>
                </li>
                <li
                        v-if="
            this.$store.getters.userClaims &&
            this.$store.getters.userClaims.includes('users-full')
          "
                >
                    <router-link
                            :to="{
              name: 'users',
            }"
                            class="sidenav-close"
                    >
                        Klienci
                        <i class="material-icons">people</i>
                    </router-link>
                </li>

                <li class="no-padding">
                    <ul class="collapsible collapsible-accordion">
                        <li class="bold">
                            <a class="collapsible-header"
                            >Raporty<i class="material-icons chevron">chevron_left</i></a
                            >
                            <div class="collapsible-body">
                                <ul>
                                    <li>
                                        <router-link
                                                :to="{
                        name: 'reportOrders',
                        params: { reportId: 'ordersDaily' },
                      }"
                                                class="sidenav-close"
                                        >
                                            Sprzedaż bieżąca
                                            <i class="material-icons">show_chart</i>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                                :to="{
                        name: 'reportOrders',
                        params: { reportId: 'ordersMonthly' },
                      }"
                                                class="sidenav-close"
                                        >
                                            Sprzedaż miesięcznie
                                            <i class="material-icons">show_chart</i>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                                :to="{
                        name: 'reportOrdersByProduct',
                      }"
                                                class="sidenav-close"
                                        >
                                            Sprzedaż według produktów
                                            <i class="material-icons">table_chart</i>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                                :to="{
                        name: 'reportOrdersByProductOverTime',
                      }"
                                                class="sidenav-close"
                                        >
                                            Historia sprzedaży produktu
                                            <i class="material-icons">bar_chart</i>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                                :to="{
                        name: 'productsWithNoOrders',
                      }"
                                                class="sidenav-close"
                                        >
                                            Produkty bez sprzedaży
                                            <i class="material-icons">table_chart</i>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                                :to="{
                        name: 'swProductsByDemand',
                      }"
                                                class="sidenav-close"
                                        >
                                            Produkty oczekiwane
                                            <i class="material-icons">table_chart</i>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                                :to="{
                        name: 'stockEvents',
                      }"
                                                class="sidenav-close"
                                        >
                                            <span class="new badge"></span>
                                            Rotacja produktów
                                            <i class="material-icons">swap_calls</i>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
            <div id="dropdown1" class="dropdown-content notifications">
                <div class="notifications-title">notifications</div>
                <div class="card">
                    <div class="card-content">
                        <span class="card-title">Joe Smith made a purchase</span>
                        <p>Content</p>
                    </div>
                    <div class="card-action">
                        <a href="#!">view</a>
                        <a href="#!">dismiss</a>
                    </div>
                </div>
                <div class="card">
                    <div class="card-content">
                        <span class="card-title">Daily Traffic Update</span>
                        <p>Content</p>
                    </div>
                    <div class="card-action">
                        <a href="#!">view</a>
                        <a href="#!">dismiss</a>
                    </div>
                </div>
                <div class="card">
                    <div class="card-content">
                        <span class="card-title">New User Joined</span>
                        <p>Content</p>
                    </div>
                    <div class="card-action">
                        <a href="#!">view</a>
                        <a href="#!">dismiss</a>
                    </div>
                </div>
            </div>
        </header>
        <main>
            <router-view v-on:error="handleErrorFromDownstream($event)"></router-view>
        </main>
        <footer class="page-footer">
            <div class="row no-print">
                <div class="col l3">
                    Zalogowany: {{ user ? user.displayName : "" }}
                    <a href="/" v-if="user">Wyloguj</a>
                </div>
                <div class="col l6"></div>
                <div class="col l3">{{ appName }}, build {{ buildObj.build }}</div>
            </div>
        </footer>

        <!-- Modal Structure -->
        <div id="modal1" class="modal">
            <div class="modal-content">
                <h4>Wystąpił problem</h4>
                <p v-if="errorMessageObj.errorCode != null">
                    {{ $t(errorMessageObj.errorCode, errorMessageObj.params) }}
                </p>
                <p v-else>{{ errorMessageObj }}</p>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect waves-green btn-flat">OK</a>
            </div>
        </div>
    </fragment>
</template>
<script>
import M from "materialize-css";
import firebase from "firebase/app";
import "firebase/auth";
import {Fragment} from "vue-fragment";
import axios from "axios";
import build from "/build.txt";

export default {
    components: {Fragment},

    props: {
        errorMessageObj: Object,
    },

    data() {
        return {
            user: null,
            errorModal: null,
            appName: process.env.VUE_APP_INSTANCE_NAME,
            buildObj: {build},
        };
    },

    computed: {},

    methods: {
        refreshAppMetrics: function () {
            if (process.env.VUE_APP_SKIP_REFRESH_METRICS === '1') {
                console.log("skipping app metrics refresh")
                return;
            }

            axios
                .get(process.env.VUE_APP_API + "/metrics")
                .then((response) => {
                    const metrics = response.data;
                    this.$store.dispatch("setAppMetrics", metrics);
                    console.log("appMetrics refreshed ");
                })
                .catch((error) => {
                    console.log("refresh appMetrics error: " + error);
                });
        },

        handleErrorFromDownstream: function (event) {
            console.log('TODO: handle event from downstream:', event)
            alert("problem: " + event) //todo: handle better
        }
    },

    watch: {
        errorMessageObj: function (value) {
            console.log(value);
            this.errorModal.open();
        },
    },

    created() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                console.log("fb logged, " + user.displayName);
                this.user = user;
                user.getIdTokenResult().then((idTokenResult) => {
                    console.log(idTokenResult.claims.szukajkaRoles);
                    this.$store.dispatch(
                        "setUserClaims",
                        idTokenResult.claims.szukajkaRoles
                    );
                });
            } else {
                console.log("fb not logged");
                this.user = null;
            }
        });

        this.refreshAppMetrics();
        setInterval(() => {
            this.refreshAppMetrics();
        }, 5 * 60 * 1000);
    },

    mounted() {
        var elems = document.querySelectorAll(".modal");
        M.Modal.init(elems, {});
        this.errorModal = M.Modal.getInstance(document.querySelector(".modal"));

        M.Sidenav.init(document.querySelectorAll(".sidenav"), {});
        M.Collapsible.init(document.querySelectorAll(".collapsible"), {
            accordion: true,
        });
        M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {
            alignment: "right",
            constrainWidth: false,
            coverTrigger: false,
            closeOnClick: false,
            onOpenEnd: function (el) {
                console.log(el.M_Dropdown);
            },
        });
    },
};
</script>

<style scoped>
@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}
</style>
