<template>
  <div>
    <h3>Aktualizacja produktów - przegląd zmian</h3>
    <div class="card-panel grey-text text-darken-1">
       
      <h2>Produkty</h2>
      Pokazuj: 
      <button @click="showSelection='REMOVED'" class="waves-effect waves-light btn red darken-2">Usunięte {{uploadDiff ? `(${uploadDiff.productsDiff.removed})` : null }}</button>
      <button @click="showSelection='ADDED'" class="waves-effect waves-light btn">Dodane {{uploadDiff ? `(${uploadDiff.productsDiff.added})` : null }}</button>
      <button @click="showSelection='MODIFIED'" class="waves-effect waves-light btn orange amber">Zmienione {{uploadDiff ? `(${uploadDiff.productsDiff.modified})` : null }}</button>
      <button @click="showSelection='UNCHANGED'" class="waves-effect waves-light btn-flat ">Bez zmian {{uploadDiff ? `(${uploadDiff.productsDiff.unmodified})` : null }}</button>
      <button @click="showSelection='ALL'" class="waves-effect waves-light btn-large">Wszystkie</button>

      <ul class="collection" style="list-style: none;">
        <li v-for="productDiff in uploadDiff.productsDiff.diffResults" :key="productDiff.id" v-bind:class="'collection-item diff'+productDiff.operation" v-show="shouldShow(productDiff.operation)">
          <del v-if="productDiff.operation == 'REMOVED'">{{productDiff.id}} : {{productDiff.title}}</del>
          <ins v-else-if="productDiff.operation == 'ADDED'">{{productDiff.id}} : {{productDiff.title}}</ins>
          <span v-else-if="productDiff.operation == 'MODIFIED'">{{productDiff.id}} : 
            <template v-for="change in productDiff.changes">
              <del v-if="change.operation == 'DELETE'" :key="change.text+change.operation">{{change.text}}</del>
              <ins v-else-if="change.operation=='INSERT'" :key="change.text+change.operation">{{change.text}}</ins>
              <span v-else :key="change.text+change.operation">{{change.text}}</span>
            </template>
          </span>
          <span v-else>{{productDiff.id}} : {{productDiff.title}}</span>
        </li>
      </ul>

      <h2>Kategorie</h2>
        <ul class="collection">
          <li v-for="categoryDiff in uploadDiff.categoryDiff.diffResults" :key="categoryDiff.id" v-bind:class="'collection-item diff'+categoryDiff.operation">
            <del v-if="categoryDiff.operation == 'REMOVED'">{{categoryDiff.name}}</del>
            <ins v-else-if="categoryDiff.operation == 'ADDED'">{{categoryDiff.name}}</ins>
            <span v-else-if="categoryDiff.operation == 'MODIFIED'">{{categoryDiff.name}} : 
              <template v-for="change in categoryDiff.changes">
                <del v-if="change.operation == 'DELETE'" :key="change.text+change.operation">{{change.text}}</del>
                <ins v-else-if="change.operation=='INSERT'" :key="change.text+change.operation">{{change.text}}</ins>
                <span v-else :key="change.text+change.operation">{{change.text}}</span>
              </template>
            </span>
            <span v-else>{{categoryDiff.name}}</span>
          </li>            
        </ul>      
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uploadDiff: Object
  },

  data() {
    return {
      showSelection: "ALL"
    };
  },

  methods: {
    shouldShow(operation) {
      return this.showSelection == "ALL" || operation == this.showSelection;
    }
  }
};
</script>

<style scoped>
ins {
  background-color: rgb(230, 255, 230);
  text-decoration: none;
}

del {
  background-color: rgb(255, 230, 230);
}

.btn, .btn-large{
  margin-left: 20px;
}
</style>
