<template>
  <div>
    <h3>Aktualizacja produktu - nowe zdjęcie</h3>  
    <div class="card-panel grey-text text-darken-1">
      <h4>{{uploadDiff.item.title}} (id: {{uploadDiff.item.id}})</h4> 
      <img v-bind:src="imagePreviewUrl" class="materialboxed responsive-img" />
    </div>
  </div>
</template>

<script>
import M from "materialize-css";

export default {
  props: {
    uploadDiff: Object
  },

  data() {
    return {};
  },

  methods: {},

  computed: {
    imagePreviewUrl() {
      return (
        process.env.VUE_APP_API +
        "/apiProducts/image?uploadId=" +
        this.uploadDiff.uploadId
      );
    }
  },

  mounted() {
    var elems = document.querySelectorAll(".materialboxed");
    M.Materialbox.init(elems, {});
  }
};
</script>

<style scoped>
ul .diff {
  list-style-type: none;
}
ins {
  background-color: rgb(230, 255, 230);
  text-decoration: none;
}

del {
  background-color: rgb(255, 230, 230);
}

.preview h2 {
  font-size: 2rem;
}
</style>
