<template>
  <div id="modalUserQuickView" class="modal bottom-sheet">
    <div class="modal-content" v-if="userDetails">

      <h4>{{ userDetails.name }}</h4>
      <h5>{{ userDetails.city }}</h5>
      <span v-if="reviewsForUser.length > 0"
        >Opinie: {{ reviewsForUser.length }}</span
      >
      <span v-if="ordersForUser.length > 0"
        >Zamówienia: {{ $n(ordersTotal, "currency") }} ({{
          ordersForUser.length
        }})</span
      >
    </div>
    <div class="modal-footer"></div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import M from "materialize-css";
import NProgress from "nprogress";

import axios from "axios";
import EventBus from "@/event-bus";

export default {
  components: {},

  data() {
    return {
      modalInstance: null,
      userDetails: null,
      reviewsForUser: [],
      ordersForUser: [],
      ordersTotal: 0,
      env: process.env,
    };
  },

  methods: {
    revealModalUserQuickView: function (userId) {
      console.log("revealModalUserQuickView", userId);
      this.modalInstance.open();

      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .get()
        .then((doc) => {
          this.userDetails = doc.data();
        });

      firebase
        .firestore()
        .collection("reviews")
        .get()
        .then((querySnapshot) => {
          const reviewsForUser = querySnapshot.docs
            .map((doc) => {
              const reviewsArray = doc.data().reviews;

              return reviewsArray.find((review) => review.userId == userId);
            })
            .filter((e) => e != undefined);

          this.reviewsForUser = reviewsForUser;
        });

      firebase
        .firestore()
        .collection("orders")
        .where("userId", "==", userId)
        // .where("status", "==", "DISPATCHED")
        .where("status", "in", ['PAID', 'DISPATCHED'])
        .orderBy("createDate", "desc")
        .get()
        .then((querySnapshot) => {
          const ordersForUser = querySnapshot.docs.map((order) => order.data());
          this.ordersForUser = ordersForUser;

          const reducerItemsCost = (accumulator, currentValue) =>
            accumulator + currentValue.itemsCost;

          this.ordersTotal = this.ordersForUser.reduce(reducerItemsCost, 0);
        });
    },
  },

  created() {
    console.log("created");
  },

  mounted() {
    this.modalInstance = M.Modal.init(
      document.querySelector("#modalUserQuickView"),
      {
        onCloseEnd: () => {
          console.log("modalUserQuickView closed");
          // (this.modalProdctDetails = null), (this.reviews = []);
        },
      }
    );

    EventBus.$on("OPEN_USER_QUICK_VIEW", (payload) => {
      console.log("on OPEN_USER_QUICK_VIEW", payload);
      this.revealModalUserQuickView(payload.userId);
    });
  },

  beforeDestroy() {
    console.log("beforeDestroy");
    EventBus.$off("OPEN_USER_QUICK_VIEW");

    this.modalInstance.close();
    this.modalInstance.destroy();
  },
};
</script>

<style scoped>
div.modal-content > span {
  margin-right: 20px;
}
</style>
