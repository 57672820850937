<template>
  <div class="container">
    <h1>Opinie</h1>
    <h5>oczekujące na zatwierdzenie ({{ reviews ? reviews.length : 0 }})</h5>

    <ul class="collection">
      <li
        v-for="review in reviews"
        :key="review.id"
        class="collection-item avatar"
        v-bind:class="{
          active: selectedReview ? review.id == selectedReview.id : false,
          pendingRemoval: removedReview ? removedReview.id == review.id : false,
        }"
      >
        <a
          href
          @click.prevent="revealModalProdctDetailsQuickView(review.item.id)"
          ><img
            v-bind:src="review.item.image"
            class="circle"
            title="szybki podgląd produktu"
        /></a>

        <p class="secondary-content">
          {{ $d(new Date(review.createDate), "medium") }}
          <a href @click.prevent="onMoreClicked(review.id)"
            ><i class="material-icons">more_vert</i></a
          >
        </p>
        <p>
          <i class="material-icons tiny" v-for="a in review.rating" :key="a"
            >grade</i
          >

          <a
            href
            @click.prevent="revealUserQuickView(review.userId)"
            style="margin-left: 20px"
            title="szybki podgląd Klienta"
            >{{ review.userName }}
            <i class="material-icons">person_outline</i></a
          >
        </p>
        <p v-html="formatHtml(review.review)"></p>
      </li>
    </ul>

    <div id="orderReviewModal" class="modal">
      <div class="modal-content">
        <h4>Moderowanie opinii</h4>
        <p v-if="selectedReview">{{ selectedReview.review }}</p>
      </div>
      <div class="modal-footer">
        <a
          class="waves-effect waves-teal btn"
          :class="{ disabled: false }"
          @click="setReviewAsApproved(selectedReview.id)"
          >Akceptuj</a
        >
        &nbsp;
        <a
          class="waves-effect waves-red btn-flat red-text"
          :class="{ disabled: false }"
          @click="setReviewAsRejected(selectedReview.id)"
          >Odrzuć</a
        >
      </div>
    </div>

    <!--  modal produktu -->
    <item-quick-view></item-quick-view>
    <user-quick-view></user-quick-view>
  </div>
</template>

<script>
import firebase from 'firebase/app'

import M from "materialize-css";
import admin from "@/admin";
import NProgress from "nprogress";

import EventBus from "@/event-bus";
import ItemQuickView from "@/components/ItemQuickView.vue";
import UserQuickView from "@/components/UserQuickView";

export default {
  components: {
    itemQuickView: ItemQuickView,
    userQuickView: UserQuickView,
  },

  data() {
    return {
      reviews: [],
      unsubscribePending: null,
      selectedReview: null,
      removedReview: null,

      modalProdctDetails: null,
      env: process.env,
    };
  },

  methods: {
    getModalInstanceOrderReview: () => {
      return M.Modal.getInstance(document.querySelector("#orderReviewModal"));
    },

    revealModalUserQuickView: (id) => {
      EventBus.$emit("OPEN_USER_QUICK_VIEW", { userId: id });
    },    

    onMoreClicked: function (reviewId) {
      console.log(`onMoreClicked ${reviewId}`);
      this.selectedReview = this.reviews.find((r) => {
        return r.id == reviewId;
      });
      this.getModalInstanceOrderReview().open();
    },

    setReviewAsRejected: (id) => {
      alert("to do...");
    },

    setReviewAsApproved: function (id) {
      console.log("setReviewAsApproved", id);
      NProgress.start();

      this.removedReview = this.reviews.find((r) => {
        return r.id == id;
      });

      //approveReview({id:'1ad5742d-4e5e-4017-a89b-514aa7db42d7'}
      let approveReviewCallable = firebase
        .functions()
        .httpsCallable("approveReview");

      approveReviewCallable({
        id: id,
      })
        .then((result) => {
          setTimeout(() => {
            admin.toastInfo("Opinia została zatwierdzona.");
          }, 555);
        })
        .catch((err) => {
          // this.operationFeedback = err.message;
        })
        .finally(() => {
          NProgress.done();
        });

      this.getModalInstanceOrderReview().close();
    },

    //modal produktu
    revealModalProdctDetailsQuickView: function (itemId) {
      EventBus.$emit("OPEN_PRODUCT_QUICK_VIEW", { itemId: itemId });
    },

    //modal usera
    revealUserQuickView: function (userId) {
      console.log("revealUserQuickView", userId);
      EventBus.$emit("OPEN_USER_QUICK_VIEW", { userId: userId });
    },

    formatHtml: (raw) => {
      return admin.plainToHtml(raw);
    },
  },

  created() {
    console.log("created");

    this.unsubscribePending = firebase
      .firestore()
      .collection("reviews")
      .doc("X-PENDING")
      .onSnapshot((doc) => {
        console.log("Current data: ", doc.data().reviews);
        var tempReviews = doc.data().reviews.sort((a, b) => {
          return b.createDate - a.createDate;
        });
        this.reviews = tempReviews;
      });
  },

  mounted() {
    M.Modal.init(document.querySelectorAll("#orderReviewModal"), {
      onCloseEnd: () => {
        this.selectedReview = null;
      },
    });
  },

  beforeDestroy() {
    console.log("beforeDestroy");
    this.unsubscribePending();
  },
};
</script>

<style scoped>
.pendingRemoval {
  background-color: indianred;
}
</style>
