<template>
  <div>
    <h3>Nowa Konfiguracja - przegląd zmian</h3>
        <div class="card-panel grey-text text-darken-1">
          <ul class="collection with-header">
            <li class="collection-header"><h4>Ogólne</h4></li>
            <li v-for="(v, k) in uploadDiff.newAppConfig.simpleConfig" :key="k" class="collection-item">
              {{v}} : {{k}}
            </li>
          </ul>

          <ul class="collection with-header">
            <li class="collection-header"><h4>Wizard</h4></li>
            <li v-for="(v, k) in uploadDiff.newAppConfig.wizardConfig" :key="k" class="collection-item">
              {{k}} : {{v}}
            </li>
          </ul>         

          <ul class="collection with-header">
            <li class="collection-header"><h4>Seo</h4></li>
            <li v-for="(cfg, index) in uploadDiff.newAppConfig.seoConfig" :key="index" class="collection-item">
              <span class="title">{{cfg.webQuery}}</span> <span class="badge">{{cfg.canonical}}</span>
              <blockquote>{{cfg.title}}<br />{{cfg.description}}</blockquote>
            </li>
          </ul>              
        </div>
  </div>
</template>

<script>
export default {
  props: {
    uploadDiff: Object
  },

  data() {
    return {};
  },

  methods: {}
};
</script>

<style scoped>
ul .diff {
  list-style-type: none;
}
ins {
  background-color: rgb(230, 255, 230);
  text-decoration: none;
}

del {
  background-color: rgb(255, 230, 230);
}
</style>
