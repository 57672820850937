<template>
  <div class="container">
    <a href @click.prevent="revealModalProdctDetailsQuickView(item.id)"
      ><img
        v-if="item && item.imgsBySize && item.imgsBySize['150']"
        v-bind:src="`${env.VUE_APP_API}/ii/${item.id}/150/${item.imgsBySize[
          '150'
        ].slice(0, 1)}`"
        style="float: right"
        title="szybki podgląd produktu"
    /></a>

    <h1>Opinie</h1>
    <h5 v-if="item">{{ item.name }}</h5>
    <h6>Zatwierdzone ({{ reviews ? reviews.length : 0 }})</h6>

    <ul class="collection">
      <li
        v-for="review in reviews"
        :key="review.id"
        class="collection-item"
        v-bind:class="{
          active: selectedReview ? review.id == selectedReview.id : false,
          pendingRemoval: removedReview ? removedReview.id == review.id : false,
        }"
      >
        <p>
          <i class="material-icons tiny" v-for="a in review.rating" :key="a"
            >grade</i
          >

          <a
            href
            @click.prevent="revealUserQuickView(review.userId)"
            style="margin-left: 20px"
            title="szybki podgląd Klienta"
            >{{ review.userName }}
            <i class="material-icons">person_outline</i></a
          >

          <span class="secondary-content">{{
            $d(new Date(review.createDate), "medium")
          }}</span>
        </p>
        <p v-html="formatHtml(review.review)"></p>
      </li>
    </ul>
    <!--  modal produktu -->
    <item-quick-view></item-quick-view>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import M from "materialize-css";
import admin from "@/admin";
import NProgress from "nprogress";
import axios from "axios";

import EventBus from "@/event-bus";
import ItemQuickView from "@/components/ItemQuickView.vue";

export default {
  props: {
    itemId: String,
  },

  components: {
    itemQuickView: ItemQuickView,
  },

  data() {
    return {
      item: null,
      reviews: [],
      reviewsListenerUnsubscribe: null,
      selectedReview: null,
      removedReview: null,

      env: process.env,
    };
  },

  methods: {
    onMoreClicked: function (reviewId) {
      console.log(`onMoreClicked ${reviewId}`);
      this.selectedReview = this.reviews.find((r) => {
        return r.id == reviewId;
      });
      // this.getModalInstanceOrderReview().open();
    },

    //modal produktu
    revealModalProdctDetailsQuickView: function (itemId) {
      EventBus.$emit("OPEN_PRODUCT_QUICK_VIEW", { itemId: itemId });
    },

    //modal usera
    revealUserQuickView: (userId) => {
      console.log("revealUserQuickView", userId);
      EventBus.$emit("OPEN_USER_QUICK_VIEW", { userId: userId });
      alert("szczegóły usera: to do");
    },

    formatHtml: (raw) => {
      return admin.plainToHtml(raw);
    },
  },

  created() {
    console.log("created", this.itemId);

    this.reviewsListenerUnsubscribe = firebase
      .firestore()
      .collection("reviews")
      .doc(this.itemId)
      .onSnapshot((doc) => {
        var tempReviews = doc.data()
          ? doc.data().reviews.sort((a, b) => {
              return b.createDate - a.createDate;
            })
          : [];
        this.reviews = tempReviews;
      });

    axios
      .get(process.env.VUE_APP_API + "/ui/item.json", {
        params: {
          id: this.itemId,
          ref: "admin:ReviewsForItem",
        },
      })
      .then((axiosResponse) => {
        this.item = axiosResponse.data.item;
      })
      .catch((error) => {
        console.error("ModalProdctDetailsQuickView error: ", error);
      });
  },

  mounted() {
    M.Modal.init(document.querySelectorAll("#orderReviewModal"), {
      onCloseEnd: () => {
        this.selectedReview = null;
      },
    });
  },

  beforeDestroy() {
    console.log("beforeDestroy");
    this.reviewsListenerUnsubscribe();
  },
};
</script>

<style scoped>
.pendingRemoval {
  background-color: indianred;
}
</style>
