var whiskyColumns = [
    { title: "Id", data: "id" },
    {
        title: "Nazwa",
        data: "name",
        render: function (data, type, row, meta) {
            // return `<a href="javascript:rowClicked('${row.id}')">${data}</a>`;
            return `<a href="javascript:$('body').trigger('szukajka:dataTable:rowClicked', [{ itemId: '${row.id}' }]);void(0);">${data}</a>`;
        }
    },
    { title: "Symbol", data: "code" },
    { title: "Stan", data: "stock" },
    {
        title: "Cena det.",
        data: function (row, type, val, meta) {
            var retVal = null;
            if (row.stockCf && row.stockCf.inetSKRESL)
                retVal = Number(
                    row.stockCf.inetSKRESL.replace(",", ".")
                ).toFixed(2);

            return retVal;
        }
    },
    {
        title: "Cena internet",
        data: function (row, type, val, meta) {
            var retVal = null;
            if (row.stockCf && row.stockCf.inetDETAL)
                retVal = Number(
                    row.stockCf.inetDETAL.replace(",", ".")
                ).toFixed(2);

            return retVal;
        }
    }
]

var rumyColumns = [
    { title: "Id", data: "id" },
    {
        title: "Nazwa",
        data: "name",
        render: function (data, type, row, meta) {
            // return `<a href="javascript:rowClicked('${row.id}')">${data}</a>`;
            return `<a href="javascript:$('body').trigger('szukajka:dataTable:rowClicked', [{ itemId: '${row.id}' }]);void(0);">${data}</a>`;
        }
    },
    { title: "Symbol", data: "code" },
    { title: "Stan", data: "stock" },
    {
        title: "Cena detal",
        data: function (row, type, val, meta) {
            var retVal = null;
            if (row.stockCf && row.stockCf.inetSKRESL)
                retVal = Number(
                    row.stockCf.inetSKRESL.replace(",", ".")
                ).toFixed(2);

            return retVal;
        }
    },
    {
        title: "Cena internet",
        data: function (row, type, val, meta) {
            var retVal = null;
            if (row.stockCf && row.stockCf.inetDETAL)
                retVal = Number(
                    row.stockCf.inetDETAL.replace(",", ".")
                ).toFixed(2);

            return retVal;
        }
    }
]


let whisky = {
    columnsConfig: whiskyColumns,
    title: 'todo'
}

let ordersDaily = (vueInstance) => {
    return {

        columnsConfig: [
            {
                title: "Data",
                data: "dateSeq",
            },
            {
                title: "Ilość zamówień",
                data: "cnt",
                className: "dt-body-center",
            },
            {
                title: "Wartość",
                data: "itemsTotal",
                render: (data, type, row, meta) => {
                    return vueInstance.$i18n.n(data, "currency");
                },
                className: "dt-body-right",
            },
        ],
        title: 'Sprzedaż'
    }
}

let ordersWeekly = (vueInstance) => {
    return {

        columnsConfig: [
            {
                title: "Rok",
                data: "year",
            },
            {
                title: "Tydzień w roku",
                data: "week",
            },
            {
                title: "Ilość zamówień",
                data: "cnt",
                className: "dt-body-center",
            },
            {
                title: "Wartość",
                data: "itemsTotal",
                render: (data, type, row, meta) => {
                    return vueInstance.$i18n.n(data, "currency");
                },
                className: "dt-body-right",
            },
        ],
        title: 'Sprzedaż tygodniowa'
    }
}

let ordersMonthly = (vueInstance) => {
    return {

        columnsConfig: [
            {
                title: "Miesiąc",
                data: "year",
                render: (data, type, row, meta) => {
                    if (type === "display" || type === "filter")
                        //https://datatables.net/manual/data/orthogonal-data
                        return data ? vueInstance.$t('monthNamesByNumber.' + row.month) + ' ' + row.year : data;
                    else
                        return row.year + '__' + row.month.toString().padStart(2, '00')
                },
            },
            {
                title: "Ilość zamówień",
                data: "cnt",
                className: "dt-body-center",
            },
            {
                title: "Wartość",
                data: "itemsTotal",
                render: (data, type, row, meta) => {
                    return vueInstance.$i18n.n(data, "currency");
                },
                className: "dt-body-right",
            },
        ],
        title: 'Sprzedaż miesięczna'
    }
}

let ordersByProduct = (vueInstance) => {
    return {

        columnsConfig: [

            {
                title: "Produkt",
                data: "name",
                render: (data, type, row, meta) => {
                    return `<a href="javascript:$('body').trigger('szukajka:dataTable:rowClicked', [{ itemId: '${row.itemId}' }]);void(0);">${data} (${row.itemId})</a>`;
                },
            },
            {
                title: "Wartość",
                data: "totalValue",
                render: (data, type, row, meta) => {
                    if (type === 'display' || type === 'filter') //https://datatables.net/manual/data/orthogonal-data
                        return vueInstance.$i18n.n(data, "currency");
                    else
                        return data
                },
                className: "dt-body-right",
            },
            {
                title: "Ilość",
                data: "totalItems",
                className: "dt-body-right",
            },
            {
                title: "Liczba zamowień",
                data: "ordersCnt",
                className: "dt-body-right",
            },
            {
                title: "Stan",
                data: "stock",
                className: "dt-body-right",
            },
        ],
        title: 'Sprzedaż według produktów'
    }
}

let productsWithNoOrders = (vueInstance) => {
    return {

        columnsConfig: [
            {
                title: "Produkt",
                data: "name",
                render: (data, type, row, meta) => {
                    return `<a href="javascript:$('body').trigger('szukajka:dataTable:rowClicked', [{ itemId: '${row.id}' }]);void(0);">${data} (${row.id})</a>`;
                },
            },
            {
                title: "Kod",
                data: "code",
            },
            {
                title: "Stan",
                data: "stock",
                className: "dt-body-right",
            },
            {
                title: "Opis",
                data: "description",
                render: (data, type, row, meta) => {
                    if (type === 'display' || type === 'filter') //https://datatables.net/manual/data/orthogonal-data
                        return data == 1 ? "opis jest" : "opis brak";
                    else
                        return data
                },

            },
        ],
        title: 'Produkty bez sprzedaży'
    }
}

let swProductsByDemand = (vueInstance) => {
    return {

        columnsConfig: [
            {
                title: "Produkt",
                data: "itemName",
                render: (data, type, row, meta) => {
                    return `<a href="javascript:$('body').trigger('szukajka:dataTable:rowClicked', [{ itemId: '${row.itemId}' }]);void(0);">${data} (${row.itemId})</a>`;
                },
            },
            {
                title: "Kod",
                data: "code",
            },
            {
                title: "Oczekujących",
                data: "cnt",
                className: "dt-body-right",
            },
            {
                title: "Ostatnio",
                data: "lastRegisteredAsSeconds",
                render: (data, type, row, meta) => {
                    return new Date(data * 1000).toISOString().substring(0, 10)
                },
            },
        ],
        title: 'Produkty oczekiwane'
    }
}

let stockEvents = (vueInstance) => {
    return {

        columnsConfig: [
            {
                title: "Data",
                data: "createDateSeconds",
                render: (data, type, row, meta) => {
                    return vueInstance.$d(new Date(data * 1000), "shortDt")
                    // return new Date(data * 1000).toISOString().substring(0, 10)
                },
                "width": "20%"
            },
            {
                title: "Produkt",
                data: "productName",
                render: (data, type, row, meta) => {
                    if (row.event === 'BACK_IN_STOCK') {
                        return `<a class="teal-text lighten-3" href="javascript:$('body').trigger('szukajka:dataTable:rowClicked', [{ itemId: '${row.itemId}' }]);void(0);">
                        ${data} (${row.itemId})<br />
                        ${row.qty} / ${vueInstance.$n(row.price, "currency")}
                        </a>`;
                    }
                    else {
                        return `<a class="red-text lighten-3" href="javascript:$('body').trigger('szukajka:dataTable:rowClicked', [{ itemId: '${row.itemId}' }]);void(0);">${data} (${row.itemId})</a>`;
                    }
                },
            },

            {
                title: "Kierunek",
                data: "event",
                render: (data, type, row, meta) => {
                    if (data === 'BACK_IN_STOCK')
                        return `<span class="teal-text lighten-3">in</span>`
                    else if (data === 'OUT_OF_STOCK')
                        return `<span class="red-text lighten-3">out</span>`

                    else return `${data}`
                },
                "width": "5%"
            },
            {
                "title":"sort",
                data: "createDateSeconds",
                "visible": false,
            }
        ],
        title: 'Rotacja produktów'
    }
}

// ToDo - dodac wsparcie dla VUE_APP_INSTANCE_ID
function columnsConfig(instanceName, reportName, vueInstance) {

    console.log('IN, RN', instanceName, reportName)

    if (reportName == 'ordersDaily')
        return ordersDaily(vueInstance)
    else if (reportName == 'ordersWeekly')
        return ordersWeekly(vueInstance)
    else if (reportName == 'ordersMonthly')
        return ordersMonthly(vueInstance)
    else if (reportName == 'ordersByProduct')
        return ordersByProduct(vueInstance)
    else if (reportName == 'productsWithNoOrders')
        return productsWithNoOrders(vueInstance)
    else if (reportName == 'swProductsByDemand')
        return swProductsByDemand(vueInstance)
    else if (reportName == 'stockEvents')
        return stockEvents(vueInstance)

    else if (reportName == 'items' && instanceName == 'whisky-prod')
        return whisky.columnsConfig
    else if (reportName == 'items' && instanceName == 'rumy-prod')
        return rumyColumns

    else
        return whisky.columnsConfig
}

export default { columnsConfig }