<template>
  <div class="container">
    <h1>Zamówienia</h1>

    <div class="row">
      <div class="col s6">
        Status:
        <br />
        <label>
          <input
            name="filterByStock"
            type="radio"
            v-model="filters.filterBy"
            value="ALL"
            checked
          />
          <span style="padding-right: 20px">Wszystkie</span>
        </label>

        <label>
          <input
            name="filterByStock"
            type="radio"
            v-model="filters.filterBy"
            value="UNPAID"
          />
          <span
            :class="`${getStylingForStatus('UNPAID').cssClass}`"
            style="padding-right: 20px"
            >{{ $t("orderStatus.UNPAID") }}</span
          >
        </label>

        <label>
          <input
            name="filterByStock"
            type="radio"
            v-model="filters.filterBy"
            value="PAID"
          />
          <span
            :class="`${getStylingForStatus('PAID').cssClass}`"
            style="padding-right: 20px"
            >{{ $t("orderStatus.PAID") }}</span
          >
        </label>

        <label>
          <input
            name="filterByStock"
            type="radio"
            v-model="filters.filterBy"
            value="DISPATCHED"
          />
          <span
            :class="`${getStylingForStatus('DISPATCHED').cssClass}`"
            style="padding-right: 20px"
            >{{ $t("orderStatus.DISPATCHED") }}</span
          >
        </label>

        <label>
          <input type="checkbox" v-model="filters.showCancelled" />
          <span>pokazuj Anulowane</span>
        </label>
      </div>

      <div class="col s6">
        Czas:
        <br />
        <label>
          <input
            name="filterByDate"
            type="radio"
            v-model="filters.filterByDate"
            value="today"
            checked
          />
          <span style="padding-right: 20px">Dzisiaj</span>
        </label>

        <label>
          <input
            name="filterByDate"
            type="radio"
            v-model="filters.filterByDate"
            value="yesterday"
            checked
          />
          <span style="padding-right: 20px">Wczoraj</span>
        </label>
        <br />
        <label>
          <input
            name="filterByDate"
            type="radio"
            v-model="filters.filterByDate"
            value="thisMonth"
            checked
          />
          <span style="padding-right: 20px">Ten miesiąc</span>
        </label>

        <label>
          <input
            name="filterByDate"
            type="radio"
            v-model="filters.filterByDate"
            value="previousMonth"
            checked
          />
          <span style="padding-right: 20px">Poprzedni miesiąc</span>
        </label>
        <br />
        <label>
          <input
            name="filterByDate"
            type="radio"
            v-model="filters.filterByDate"
            value="ALL"
            checked
          />
          <span style="padding-right: 20px">Wszystkie</span>
        </label>
      </div>
    </div>

    <table class="centered">
      <thead>
        <tr>
          <th style="font-size: large">{{ aggregated.orderCount }}</th>
          <th></th>
          <th></th>
          <th style="font-size: large">
            {{ $n(aggregated.itemsCost, "currency") }}
          </th>
          <th style="font-size: large">
            {{ $n(aggregated.totalOrderCost, "currency") }}
          </th>
        </tr>
        <tr>
          <th></th>
          <th>Numer</th>
          <th>Data</th>
          <th>Wartość towary</th>
          <th>Wartość całkowita</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(order, index) in orders" :key="order.id">
          <td>
            <span
              v-if="order.isNew"
              class="badge new"
              data-badge-caption="nowe"
            ></span>
            {{ index + 1 }}.
          </td>
          <td>
            <router-link
              :to="{ name: 'order', params: { orderId: order.id } }"
              >{{ order.data.orderNumber }}</router-link
            >
          </td>
          <td>
            {{ $d(new Date(order.data.createDate.seconds * 1000), "medium") }}
            <br />
            <a
              href
              @click.prevent="revealModalUserQuickView(order.data.userId)"
              title="szybki podgląd Klienta"
              v-if="order.data.access != 'public'"
            >
              {{ order.data.deliveryAddress.name }}</a
            >
            <i v-else>{{ order.data.deliveryAddress.name }}</i>
          </td>
          <td>{{ $n(order.data.itemsCost, "currency") }}</td>
          <td>{{ $n(order.data.totalOrderCost, "currency") }}</td>
          <td>
            <span
              :class="`${getStylingForStatus(order.data.status).cssClass}`"
              >{{ $t("orderStatus." + order.data.status) }}</span
            >

            <i
              v-if="
                order.data.processingInfo &&
                order.data.processingInfo.status == 'COLLECTING_ITEMS'
              "
              class="material-icons right"
              title="w przygotowaniu"
              >directions_run</i
            >
            <i
              v-else-if="
                order.data.processingInfo &&
                order.data.processingInfo.status == 'READY_FOR_PICKUP'
              "
              class="material-icons right"
              title="oczekuje na kuriera"
              >departure_board</i
            >
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th style="font-size: large">
            {{ $n(aggregated.itemsCost, "currency") }}
          </th>
          <th style="font-size: large">
            {{ $n(aggregated.totalOrderCost, "currency") }}
          </th>
        </tr>
      </tfoot>
    </table>

    <user-quick-view></user-quick-view>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import firebase from "firebase/app";
import "firebase/firestore";
import admin from "@/admin";
import moment from "moment";

import EventBus from "@/event-bus";
import UserQuickView from "@/components/UserQuickView";

export default {
  components: {
    userQuickView: UserQuickView,
  },

  data() {
    return {
      orders: [],
      filters: null,
      componentCreatedTimestamp: null,
      unsubscribe: null,
    };
  },

  watch: {
    filters: {
      handler(newVal, oldVal) {
        console.log("filters changed...", oldVal, newVal);
        if (oldVal != null) {
          console.log("persist new filter");
          this.$store.dispatch("setOrdersFilter", newVal);
        }
        this.fetchOrders();
      },
      deep: true,
    },
  },

  computed: {
    aggregated() {
      const reducerItemsCost = (accumulator, currentValue) =>
        accumulator + currentValue.data.itemsCost;

      const reducerTotalOrderCost = (accumulator, currentValue) =>
        accumulator + currentValue.data.totalOrderCost;

      const reducerOrderCount = (accumulator, currentValue) => accumulator + 1;

      return {
        itemsCost: this.orders.reduce(reducerItemsCost, 0),
        totalOrderCost: this.orders.reduce(reducerTotalOrderCost, 0),
        orderCount: this.orders.reduce(reducerOrderCount, 0),
      };
    },
  },

  methods: {
    fetchOrders() {
      console.log("fetching orders...", this.filters);
      if (this.unsubscribe) this.unsubscribe();

      let ordersRef = firebase.firestore().collection("orders");
      if (this.filters.filterBy != "ALL")
        ordersRef = ordersRef.where("status", "==", this.filters.filterBy);

      let now = new Date();
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date(Date.now() - 86400000);
      yesterday.setHours(0, 0, 0, 0);

      let thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      thisMonth.setHours(0, 0, 0, 0);

      var prevMonthStart = new moment()
        .subtract(1, "months")
        .date(1)
        .hour(0)
        .minute(0)
        .second(0)
        .toDate();
      var thisMonthStart = new moment()
        .date(1)
        .hour(0)
        .minute(0)
        .second(0)
        .toDate();

      console.log("today", today);
      console.log("yesterday", yesterday);
      console.log("thisMonth", thisMonth);
      console.log("thisMonthStart", thisMonthStart);
      console.log("prevMonthStart", prevMonthStart);

      if (this.filters.filterByDate == "today")
        ordersRef = ordersRef.where("createDate", ">", today);

      if (this.filters.filterByDate == "yesterday")
        ordersRef = ordersRef
          .where("createDate", ">", yesterday)
          .where("createDate", "<", today);

      if (this.filters.filterByDate === "thisMonth")
        ordersRef = ordersRef.where("createDate", ">", thisMonth);

      if (this.filters.filterByDate === "previousMonth")
        ordersRef = ordersRef
          .where("createDate", ">", prevMonthStart)
          .where("createDate", "<", thisMonthStart);

      this.unsubscribe = ordersRef
        .orderBy("createDate", "desc")
        .onSnapshot((querySnapshot) => {
          console.log("on snapshot", querySnapshot.size);

          this.orders = [];
          querySnapshot.forEach((doc) => {
            let isNew =
              new Date(doc.data().createDate.seconds * 1000) >
              this.componentCreatedTimestamp;

            if (this.filters.showCancelled || doc.data().status != "CANCELLED")
              this.orders.push({ id: doc.id, data: doc.data(), isNew: isNew });
          });
        });
    },

    getStylingForStatus: (status) => {
      let ret = admin.orderStatuses[status];
      if (!ret) ret = admin.orderStatuses.UNKNOWN;
      return ret;
    },

    revealModalUserQuickView: (id) => {
      EventBus.$emit("OPEN_USER_QUICK_VIEW", { userId: id });
    },
  },

  created() {
    this.componentCreatedTimestamp = new Date();
    console.log("created,...");
    // this.user = firebase.auth().currentUser;

    // przywróć filtry ze store
    let ordersFilter = this.$store.getters.ordersFilter;
    if (ordersFilter) this.filters = ordersFilter;
    else
      this.filters = {
        showCancelled: false,
        filterBy: "ALL",
        filterByDate: "ALL",
      };
  },
};
</script>
