<template>
  <div>
    <button @click="doAction">do</button>
    <button @click="touchOrder">TO</button>
    {{orders.length}}
    <ol>
      <li v-for="order in orders" :key="order">{{order}}</li>
    </ol>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import firebase from 'firebase/app'
import "firebase/firestore";
import admin from "@/admin";

export default {
  data() {
    return {
      orders: []
    };
  },

  methods: {
    doAction() {
      console.log("do action...");
      this.orders = [];

      firebase
        .firestore()
        .collection("orders")
        .orderBy("createDate", "desc")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // console.log("docid", doc.id);
            this.orders.push(doc.id);
          });

          // setInterval(() => {
          //   this.touchOrder();
          // }, 2 * 1000);
        });
    },

    touchOrder() {
      let order = this.orders.shift();
      console.log("touching...", order);

      let orderTouchCallable = firebase.functions().httpsCallable("orderTouch");

      orderTouchCallable({
        orderId: order
      }).then(res => {
        console.log(res);
      });
    },

    created() {}
  }
};
</script>