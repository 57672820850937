<template>
  <div class="container">
    <h1>{{ selectedReportConfig.title }}</h1>

    <div class="card">
      <table
        id="swProductsByDemandTable"
        class="row-border hover order-column"
        cellspacing="0"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th
              v-for="index in this.selectedReportConfig.columnsConfig.length"
              :key="index"
            >
              C{{ index }}
            </th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th class="dt-head-right"></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <!-- pola do przekazywania wartosci z vue do callbackow tabeli-->
    <span id="reportId"></span>
    <!--  modal produktu -->
    <item-quick-view></item-quick-view>
  </div>
</template>

<script>
import reportConfig from "./reportConfig";
import EventBus from "@/event-bus";
import firebase from "firebase/app";
import ItemQuickView from "@/components/ItemQuickView.vue";

export default {
  props: {
    tableColumns: null,
    reportTitle: String,
  },

  components: {
    itemQuickView: ItemQuickView,
  },

  data() {
    return {
      reportId: "swProductsByDemand",
    };
  },

  computed: {
    selectedReportConfig() {
      return reportConfig.columnsConfig(null, "swProductsByDemand", this);
    },
  },

  watch: {},

  methods: {
    doInit: function () {
      $("#reportId").val(this.reportId);

      //https://datatables.net/examples/ajax/objects.html
      $("#swProductsByDemandTable").DataTable().destroy();

      var table = $("#swProductsByDemandTable").DataTable({
        ajax: function (data, callback, settings) {
          let orderUpdateStatusCallable = firebase
            .functions()
            .httpsCallable("bqCustomQuery");

          orderUpdateStatusCallable({
            q: "swProductsByDemand",
          })
            .then((result) => {
              callback({
                data: result.data,
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        },
        columns: this.selectedReportConfig.columnsConfig,

        language: {
          search: "",
          searchPlaceholder: "Szukaj po nazwie, id itp...",
          info: "Pokazywane wiersze _START_ - _END_ z _TOTAL_",
        },
        order: [2, "desc"],

        dom: 'ft<"footer-wrapper"l<"paging-info"ip>B>',
        scrollY: "35vh",
        // scrollCollapse: true,
        pagingType: "full",
        paging: false,
        buttons: ["copy", "csv", "excel", "pdf", "print"],
        drawCallback: function (settings) {
          var api = this.api();

          // Add waves to pagination buttons
          $(api.table().container()).find(".paginate_button");

          api.table().columns.adjust();
        },
        footerCallback: function (tfoot, data, start, end, display) {
          var total = data.reduce((prev, curr) => prev + curr.cnt, 0);
          $(tfoot)
            .find("th")
            .eq(2)
            .html("Suma: " + total);
        },
      });
    },
  },

  mounted: function () {
    this.doInit();
    $("body").on("szukajka:dataTable:rowClicked", (event, data) => {
      console.log("clicked", data);
      EventBus.$emit("OPEN_PRODUCT_QUICK_VIEW", { itemId: data.itemId });
    });
  },

  destroyed: function () {
    $("body").off("szukajka:dataTable:rowClicked");
    console.log("destroyed");
  },

  beforeRouteUpdate(to, from, next) {
    console.log(`beforeRouteUpdate: ${to.params.reportId} `);
    this.reportId = to.params.reportId;
    this.doInit();
    next();
  },
};
</script>

<style scoped>
.container {
  width: 90%;
}
</style>
