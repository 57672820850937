import axios from "axios";
import firebase from 'firebase/app'

// import "firebase/auth";

var exchangeToken
var config

var interval
function init(aConfig) {
    config = aConfig
    // refreshAuthToken();
    // interval = setUpInterval()
}

function setUpInterval() {
    return setInterval(() => {
        refreshAuthToken();
    }, 6 * 60 * 1000);
}

function refreshAuthToken() {

    console.log("refreshAuthToken...");

    const formData = new FormData();
    formData.append('exchangeToken', exchangeToken);

    axios
        .post(process.env.VUE_APP_API + "/api/auth/getAuthToken", formData)
        .then(response => {
            const authToken = response.data.authToken;
            //odrejestruj poprzedni handler
            axios.defaults.headers.common["Authorization"] = authToken;

            console.log("auth token refreshed.");
            config.refreshAuthTokenSuccessCallback(authToken)

        })
        .catch(error => {
            console.log("RAT error: " + error);

            clearInterval(interval)

            config.refreshAuthTokenFailedCallback()
        });
}

function refreshExchangeToken(u, p) {
    console.log("refreshExchangeToken...");

    const formData = new FormData();
    formData.append('username', u);
    formData.append('password', p);

    return axios
        .post(process.env.VUE_APP_API + "/api/auth/getExchangeToken", formData)
        .then(response => {
            exchangeToken = response.data.exchangeToken;
            const firebaseToken = response.data.firebaseToken

            return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
                .then(function () {
                    return firebase.auth().signInWithCustomToken(firebaseToken)
                })
                .then(function () {
                    console.log("exchange token refreshed");
                    refreshAuthToken();
                    interval = setUpInterval()

                    // config.refreshExchangeTokenSuccessCallback()
                    Promise.resolve()
                })
                .catch(function (error) {
                    // Handle Errors here.
                    console.error(error);
                    config.refreshExchangeTokenFailedCallback()
                });

        })
        .catch(error => {
            console.log("catch from function: " + error);
            return Promise.reject("blah")
        });
}

export default { init, refreshExchangeToken }