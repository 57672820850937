<template>
  <div class="container">
    <h1>{{ selectedReport.name }}</h1>

    <div class="row">
      <div class="col s4">
        Stan:
        <br />
        <label>
          <input
            name="filterByStock"
            type="radio"
            v-model="filterByStock"
            value="all"
            checked
          />
          <span style="padding-right: 20px">Wszystkie</span>
        </label>

        <label>
          <input
            name="filterByStock"
            type="radio"
            v-model="filterByStock"
            value="1"
          />
          <span style="padding-right: 20px">Dostępne</span>
        </label>

        <label>
          <input
            name="filterByStock"
            type="radio"
            v-model="filterByStock"
            value="0"
          />
          <span style="padding-right: 20px">Niedostępne</span>
        </label>
      </div>
      <div class="col s4">
        Opisy:
        <br />
        <label>
          <input
            name="filterByDescription"
            type="radio"
            v-model="filterByDescription"
            value="all"
            checked
          />
          <span style="padding-right: 20px">Wszystkie</span>
        </label>

        <label>
          <input
            name="filterByDescription"
            type="radio"
            v-model="filterByDescription"
            value="1"
          />
          <span style="padding-right: 20px">Uzupełnione</span>
        </label>

        <label>
          <input
            name="filterByDescription"
            type="radio"
            v-model="filterByDescription"
            value="0"
          />
          <span style="padding-right: 20px">Brakujące</span>
        </label>
      </div>

      <div class="col s4">
        Zdjęcia:
        <br />
        <label>
          <input
            name="filterByImage"
            type="radio"
            v-model="filterByImage"
            value="all"
            checked
          />
          <span style="padding-right: 20px">Wszystkie</span>
        </label>

        <label>
          <input
            name="filterByImage"
            type="radio"
            v-model="filterByImage"
            value="1"
          />
          <span style="padding-right: 20px">Dodane</span>
        </label>

        <label>
          <input
            name="filterByImage"
            type="radio"
            v-model="filterByImage"
            value="0"
          />
          <span style="padding-right: 20px">Brakujące</span>
        </label>
      </div>
    </div>

    <div class="card">
      <table
        id="itemsTable"
        class="row-border hover order-column"
        cellspacing="0"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th>Id</th>
            <th>Nazwa</th>
            <th>Kod</th>
            <th>Stan</th>
            <th>cena2</th>
            <th>cena2</th>
          </tr>
        </thead>
      </table>
    </div>
    <!--  modal produktu -->
    <item-quick-view></item-quick-view>
  </div>
</template>

<script>
import reportConfig from "./reportConfig";

import EventBus from "@/event-bus";
import ItemQuickView from "@/components/ItemQuickView.vue";

export default {
  props: {},

  components: {
    itemQuickView: ItemQuickView,
  },

  data() {
    return {
      reportId: this.$route.params.reportId,
      availableReports: {
        allItemsInJpk: {
          name: "Produkty",
          id: "allItemsInJpk",
          description: "Wszystkie produkty z oferty.",
          config: {
            filterByStock: "all",
            filterByImage: "all",
            filterByDescription: "all",
          },
        },
      },
      // filterByStock: this.selectedReport.config.filterByStock,
      // filterByImage: this.selectedReport.config.filterByImage
      filterByStock: "all",
      filterByImage: "all",
      filterByDescription: "all",
    };
  },

  computed: {
    selectedReport() {
      return this.availableReports
        ? this.availableReports[this.$route.params.reportId]
        : {};
    },
  },

  watch: {
    filterByStock: function (after, before) {
      this.doInit();
    },

    filterByImage: function (after, before) {
      this.doInit();
    },

    filterByDescription: function (after, before) {
      this.doInit();
    },
  },

  methods: {
    doInit: function () {
      //https://datatables.net/examples/ajax/objects.html
      $("#itemsTable").DataTable().destroy();

      var table = $("#itemsTable").DataTable({
        ajax: {
          url:
            process.env.VUE_APP_API +
            "/api/query/items/allItemsInJpk" +
            // this.reportId +
            `?stock=${this.filterByStock}&image=${this.filterByImage}&description=${this.filterByDescription}`,
          dataSrc: "items",
          headers: {
            Authorization: "Bearer " + this.$store.getters.authToken,
          },
        },
        columns: reportConfig.columnsConfig(process.env.VUE_APP_INSTANCE_ID, "items"),
        language: {
          search: "",
          searchPlaceholder: "Szukaj po nazwie, kodzie itp...",
          info: "Pokazywane wiersze _START_ - _END_ z _TOTAL_",
        },
        order: [0, "asc"],
        dom: 'ft<"footer-wrapper"l<"paging-info"ip>B>',
        scrollY: "35vh",
        // scrollCollapse: true,
        pagingType: "full",
        paging: false,
        buttons: ["copy", "csv", "excel", "pdf", "print"],
        drawCallback: function (settings) {
          var api = this.api();

          // Add waves to pagination buttons
          $(api.table().container()).find(".paginate_button");

          api.table().columns.adjust();
        },
      });
    },
  },

  mounted: function () {
    this.doInit();

    //mapowanie z eventu jQuery na event vue
    $("body").on("szukajka:dataTable:rowClicked", (event, data) => {
      console.log("clicked", data.itemId);
      EventBus.$emit("OPEN_PRODUCT_QUICK_VIEW", { itemId: data.itemId });
    });
  },

  destroyed: function () {
    $("body").off("szukajka:dataTable:rowClicked");
    console.log("destroyed");
  },

  beforeRouteUpdate(to, from, next) {
    console.log(`beforeRouteUpdate: ${to.params.reportId} `);
    this.reportId = to.params.reportId;
    this.doInit();
    next();
  },
};
</script>

<style scoped>
.container {
  width: 90%;
}
</style>
