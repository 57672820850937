<template>
  <div class="container">
    <h1>{{ selectedReportConfig.title }}</h1>

    <div v-show="false" class="row">
      <div class="col s12 l8">
        Okres:
        <br />
        <label>
          <input
            name="dateRange"
            type="radio"
            value="all"
            v-model="dateRange"
            checked
          />
          <span style="padding-right: 20px">Wszystko</span>
        </label>

        <label>
          <input
            name="dateRange"
            type="radio"
            value="thisMonth"
            v-model="dateRange"
          />
          <span style="padding-right: 20px">Ten miesiąc</span>
        </label>

        <label>
          <input
            name="dateRange"
            type="radio"
            value="previousMonth"
            v-model="dateRange"
          />
          <span style="padding-right: 20px">Poprzedni miesiąc</span>
        </label>
      </div>
      <div class="col s6 l2">
        <input
          type="text"
          class="datepicker"
          name="dateRangeFrom"
          id="dateRangeFrom"
        />
        <label for="dateRangeFrom">Data od</label>
      </div>
      <div class="col s6 l2">
        <input
          type="text"
          class="datepicker"
          name="dateRangeTo"
          id="dateRangeTo"
        />
        <label for="dateRangeTo">Data do</label>
      </div>
    </div>

    <div class="card">
      <table
        id="ordersTable"
        class="row-border hover order-column"
        cellspacing="0"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th
              v-for="index in this.selectedReportConfig.columnsConfig.length"
              :key="index"
            >
              C{{ index }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <!-- pola do przekazywania wartosci z vue do callbackow tabeli-->
    <span id="dateRange"></span>
    <span id="dateRangeFrom"></span>
    <span id="dateRangeTo"></span>
    <span id="reportId"></span>
    <!--  modal produktu -->
    <item-quick-view></item-quick-view>
  </div>
</template>

<script>
import reportConfig from "./reportConfig";
import EventBus from "@/event-bus";
import firebase from 'firebase/app'
import ItemQuickView from "@/components/ItemQuickView.vue";
import M from "materialize-css";

export default {
  props: {
    tableColumns: null,
    reportTitle: String,
  },

  components: {
    itemQuickView: ItemQuickView,
  },

  data() {
    return {
      reportId: "ordersByProduct",
      datePickerInstances: null,
      dateRange: "all",
      dateRangeFrom: null,
      dateRangeTo: null,
    };
  },

  computed: {
    selectedReportConfig() {
      return reportConfig.columnsConfig(null, "productsWithNoOrders", this);
    },
  },

  watch: {
    dateRange: function (after, before) {
      console.log("dr", after);
      $("#dateRange").val(after); // przekazanie wartosci do scope widzianego przez jQuery
      if (after) {
        this.dateRangeFrom = null;
        this.dateRangeTo = null;
        $("#dateRangeFrom").val(null);
        $("#dateRangeTo").val(null);
        // this.datePickerInstances[0]._handleClearClickBound() // dupa z tym, uzywanie internal methods zamiast oficjalnego API
        this.datePickerInstances[0].el.value = null;
        this.datePickerInstances[1].el.value = null;
        this.doInit();
      }
    },
    dateRangeFrom: function (after, before) {
      if (after) {
        $("#dateRangeFrom").val(after.toISOString().substring(0, 10)); // przekazanie wartosci do scope widzianego przez jQuery
        if (this.dateRangeTo) {
          $("#dateRange").val(null);
          this.dateRange = null;
        }
        if (this.dateRangeTo && this.dateRangeFrom) this.doInit();
      }
    },
    dateRangeTo: function (after, before) {
      if (after) {
        $("#dateRangeTo").val(after.toISOString().substring(0, 10)); // przekazanie wartosci do scope widzianego przez jQuery
        if (this.dateRangeFrom) {
          $("#dateRange").val(null);
          this.dateRange = null;
        }
        if (this.dateRangeTo && this.dateRangeFrom) this.doInit();
      }
    },
  },

  methods: {
    doInit: function () {
      $("#reportId").val(this.reportId);

      //https://datatables.net/examples/ajax/objects.html
      $("#ordersTable").DataTable().destroy();

      var table = $("#ordersTable").DataTable({
        ajax: function (data, callback, settings) {
          let orderUpdateStatusCallable = firebase
            .functions()
            .httpsCallable("bqCustomQuery");

          var dateRange = $("#dateRange").val();
          orderUpdateStatusCallable({
            dateRange: dateRange,
            dateRangeFrom: $("#dateRangeFrom").val(),
            dateRangeTo: $("#dateRangeTo").val(),
            q: 'productsWithNoOrders',
          })
            .then((result) => {
              callback({
                data: result.data,
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        },
        columns: this.selectedReportConfig.columnsConfig,

        language: {
          search: "",
          searchPlaceholder: "Szukaj po nazwie, id itp...",
          info: "Pokazywane wiersze _START_ - _END_ z _TOTAL_",
        },
        order: [1, "desc"],

        dom: 'ft<"footer-wrapper"l<"paging-info"ip>B>',
        scrollY: "35vh",
        // scrollCollapse: true,
        pagingType: "full",
        paging: false,
        buttons: ["copy", "csv", "excel", "pdf", "print"],
        drawCallback: function (settings) {
          var api = this.api();

          // Add waves to pagination buttons
          $(api.table().container()).find(".paginate_button");

          api.table().columns.adjust();
        },
      });
    },
  },

  mounted: function () {
    this.doInit();
    $("body").on("szukajka:dataTable:rowClicked", (event, data) => {
      console.log("clicked", data);
      EventBus.$emit("OPEN_PRODUCT_QUICK_VIEW", { itemId: data.itemId });
    });

    this.datePickerInstances = M.Datepicker.init(
      document.querySelectorAll(".datepicker"),
      {
        autoClose: true,
        format:'yyyy-mm-dd',
        minDate: new Date("2020-01-01"),
        onSelect: function (date) {
          $("body").trigger("szukajka:ordersByProduct:dateFrom:clicked", [
            { date: date, source: this.el.name },
          ]);
        },
        i18n: {
          clear: "Wyczyść",
          cancel: "Anuluj",
          months: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
          ],
          monthsShort: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
          ],
          weekdays: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
          ],
          weekdaysShort: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
          ],
          weekdaysAbbrev: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
        },
      }
    );

    $("body").on("szukajka:ordersByProduct:dateFrom:clicked", (event, data) => {
      if (data.source == "dateRangeFrom") this.dateRangeFrom = data.date;
      else if (data.source == "dateRangeTo") this.dateRangeTo = data.date;
    });
  },

  destroyed: function () {
    $("body").off("szukajka:dataTable:rowClicked");
    $("body").off("szukajka:ordersByProduct:dateFrom:clicked");
    console.log("destroyed");
  },

  beforeRouteUpdate(to, from, next) {
    console.log(`beforeRouteUpdate: ${to.params.reportId} `);
    this.reportId = to.params.reportId;
    this.doInit();
    next();
  },
};
</script>

<style scoped>
.container {
  width: 90%;
}
</style>
