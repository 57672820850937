<template>
    <div style="display:inline-block">
        <a class="btn waves-effect waves-light" :class="OrderStatusCancelled.cssButtonClass"
           @click="openSetCancelledDialog">
            Anuluj
        </a>

        <div class="modal orderDetailsModal" id="modalSetOrderCancelled">
            <div class="modal-content" v-if="order">
                <h4 :class="OrderStatusCancelled.cssClass">{{ order.orderNumber }} - Anulowanie</h4>
                <p>Czy napewno anulować to zamowienie?</p>
                <p v-if="false">
                    <label>
                        <input
                                type="checkbox"
                                class="indeterminate-checkbox"
                                checked="checked"
                                v-model="notifyCustomer"
                        />
                        <span>Powiadom Klienta</span>
                    </label>
                </p>
                <p class="input-field">
                    <textarea id="comment" class="materialize-textarea" v-model="cancelComment"></textarea>
                    <label for="textarea1">Powód anulowania (opcja)</label>
                </p>
                <p
                        class="red-text text-darken-2"
                        v-if="operationFeedback"
                >Wystąpił problem: {{ $t(operationFeedback) }}</p>
            </div>
            <div class="modal-footer">
                {{ order.id }}
                <a
                        class="waves-effect btn-flat modal-close"
                        :class="{disabled: inProgress}"
                >Nie</a>&nbsp;
                <a
                        class="waves-effect waves-teal btn"
                        :class="{disabled: inProgress}"
                        @click="setOrderAsCancelled"
                >Tak, Anuluj</a>
            </div>
        </div>
    </div>
</template>

<script>
import M from "materialize-css";
import firebase from 'firebase/app'
import functions from "firebase/functions";
import admin from "@/admin";
import NProgress from "nprogress";

export default {
    props: {
        order: Object,
        orderId: null
    },

    data() {
        return {
            inProgress: false,
            operationFeedback: null,
            notifyCustomer: false,
            cancelComment: null
        };
    },

    computed: {
        OrderStatusCancelled() {
            return admin.orderStatuses.CANCELLED;
        }
    },

    methods: {
        openSetCancelledDialog: function () {
            console.log('openSetCancelledDialog...')
            this.modalSetOrderCancelled().open();
        },

        setOrderAsCancelled: function () {
            NProgress.start();
            this.inProgress = true;
            this.operationFeedback = null;

            let orderUpdateStatusCallable = firebase
                .functions()
                .httpsCallable("orderUpdateStatus");

            orderUpdateStatusCallable({
                orderId: this.orderId,
                status: admin.orderStatuses.CANCELLED.code,
                notify: this.notifyCustomer,
                comment: this.cancelComment
            })
                .then(result => {
                    this.modalSetOrderCancelled().close();
                    setTimeout(() => {
                        admin.toastInfo("Gotowe.");
                    }, 555);
                })
                .catch(err => {
                    this.operationFeedback = err.message;
                })
                .finally(() => {
                    this.inProgress = false;
                    NProgress.done();
                });
        },

        modalSetOrderCancelled: function () {
            return M.Modal.getInstance(document.querySelector("#modalSetOrderCancelled"));
        }
    },

    created: function () {
    },

    mounted: function () {

        var elems = document.querySelectorAll(".orderDetailsModal");
        M.Modal.init(elems, {
            onCloseEnd: () => {
                this.operationFeedback = null;
            }
        });
    }
};
</script>

<style scoped>
</style>
