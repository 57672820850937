import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage

  // przepuszczamy do persistence tylko pola preferencji, a nie związane z zalogowaniem itp.
  reducer: state => ({
    ordersFilter: state.ordersFilter
  })
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    inProgress: false,
    loggedIn: false,
    appMetrics: null,
    authToken: null,
    ordersFilter: null,
    userClaims: null
  },

  getters: {
    inProgress: (state) => { return state.inProgress },
    loggedIn: (state) => { return state.loggedIn },
    appMetrics: (state) => { return state.appMetrics },
    authToken: (state) => { return state.authToken },
    ordersFilter: (state) => { return state.ordersFilter },
    userClaims: (state) => { return state.userClaims },
  },

  mutations: {
    setProgress: (state, payload) => {
      if (payload.progress)
        state.inProgress = true
      else
        state.inProgress = false
    },

    setLoggedIn: (state, payload) => {
      if (payload.loggedIn)
        state.loggedIn = true
      else
        state.loggedIn = false
    },

    setAppMetrics: (state, payload) => {
      state.appMetrics = payload
    },

    setAuthToken: (state, payload) => {
      state.authToken = payload
    },

    setOrdersFilter: (state, payload) => {
      state.ordersFilter = payload
    },

    setUserClaims: (state, payload) => {
      state.userClaims = payload
    }
  },

  actions: {
    startProgress: (context) => {
      context.commit('setProgress', { progress: true })
    },
    endProgress: (context) => {
      context.commit('setProgress', { progress: false })
    },
    setLoggedIn: context => {
      context.commit('setLoggedIn', { loggedIn: true })
    },
    setLoggedOut: context => {
      context.commit('setLoggedIn', { loggedIn: false })
    },
    setAppMetrics: (context, payload) => {
      context.commit('setAppMetrics', payload)
    },
    setAuthToken: (context, payload) => {
      context.commit('setAuthToken', payload)
    },
    setOrdersFilter: (context, payload) => {
      context.commit('setOrdersFilter', payload)
    },
    setUserClaims: (context, payload) => {
      context.commit('setUserClaims', payload)
    }
  }
})
