<template>
    <div>
        <div class="container" v-if="order">
            <h5 class="right">
                {{ $d(new Date(order.createDate.seconds * 1000), "long") }}
            </h5>
            <h2>{{ order.orderNumber }}</h2>
            <h6
                    v-if="
          order.processingInfo &&
          order.processingInfo.status == 'COLLECTING_ITEMS'
        "
                    class="right"
            >
                W przygotowaniu
                <i class="material-icons right medium" title="w przygotowaniu"
                >directions_run</i
                ><br/>
                <a
                        href
                        @click.prevent="toggleProcessingStatus('collecting_completed')"
                        title="Oznacz jako spakowane"
                ><i class="material-icons">departure_board</i>
                </a>
                <a href @click.prevent="toggleProcessingStatus('cancel')" class="right"
                >anuluj</a
                >
            </h6>
            <h6
                    v-else-if="
          order.processingInfo &&
          order.processingInfo.status == 'READY_FOR_PICKUP'
        "
                    class="right"
            >
                Oczekuje na kuriera
                <i class="material-icons right medium" title="oczekuje na kuriera"
                >departure_board</i
                ><br/>
                <a href @click.prevent="toggleProcessingStatus('cancel')" class="right"
                >anuluj</a
                >
            </h6>
            <a
                    v-if="
          order.status == 'PAID' &&
          (!order.processingInfo ||
            (order.processingInfo && !order.processingInfo.status))
        "
                    class="waves-effect waves-light btn right no-print"
                    @click="toggleProcessingStatus('collecting_start')"
            ><i class="material-icons left">directions_run</i>Rozpocznij obsługę
            </a>

            <h4>
                {{ order.deliveryAddress.name }}
                <a
                        href
                        @click.prevent="revealModalUserQuickView(order.userId)"
                        title="szybki podgląd Klienta"
                >
                    <i class="material-icons small">person_outline</i>
                </a>
            </h4>
            <h6>{{ order.userEmail }}</h6>
            <h5 v-bind:class="OrderStatusMeta.cssClass">
                {{ $t("orderStatus." + order.status) }}
                <small v-if="order.status === 'CANCELLED'">{{order.cancelComment}}</small>
            </h5>
            <div class="no-print">
                <order-change-status-paid
                        :order="order"
                        :orderId="orderId"
                        v-show="order.status == 'UNPAID'"
                ></order-change-status-paid
                >&nbsp;
                <order-change-status-cancelled
                        :order="order"
                        :orderId="orderId"
                        v-if="order.status == 'UNPAID'"
                ></order-change-status-cancelled
                >&nbsp;
                <order-change-status-dispatched
                        :order="order"
                        :orderId="orderId"
                        v-show="order.status == 'PAID'"
                ></order-change-status-dispatched>
                &nbsp;
                <order-change-status-cancelled
                    :order="order"
                    :orderId="orderId"
                    v-if="order.status == 'PAID'"
                ></order-change-status-cancelled>
            </div>
            <div class="section" v-if="order">
                <table class="highlight">
                    <tbody>
                    <template v-for="item in order.items">
                        <tr :key="item.id">
                            <td>
                                <a
                                        href
                                        @click.prevent="revealModalProdctDetailsQuickView(item.id)"
                                >{{ item.name }}</a
                                >
                                <div v-if="item.meta">{{ item.meta.symbol }}</div>
                            </td>
                            <td>{{ item.amount }}</td>
                            <td>{{ $n(item.price, "currency") }}</td>
                            <td>{{ $n(item.price * item.amount, "currency") }}</td>
                            <td>
                                <img
                                        v-if="
                      itemsFromOrder &&
                      itemsFromOrder[item.id] &&
                      itemsFromOrder[item.id].imgsBySize &&
                      itemsFromOrder[item.id].imgsBySize['150']
                    "
                                        :src="`${env.VUE_APP_API}/ii/${item.id}/150/${
                      itemsFromOrder[item.id].imgsBySize['150'][0]
                    }`"
                                />
                            </td>
                        </tr>
                    </template>
                    <tr class="summary">
                        <td></td>
                        <td colspan="2">Suma:</td>
                        <td>{{ $n(order.itemsCost, "currency") }}</td>
                    </tr>
                    <tr>
                        <td colspan="3">{{ order.delivery.name }}</td>
                        <td>{{ $n(order.delivery.cost, "currency") }}</td>
                    </tr>
                    <tr class="superSummary">
                        <td colspan="3">Razem do zapłaty</td>
                        <td>{{ $n(order.totalOrderCost, "currency") }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="section" v-if="order">
                <div class="row">
                    <div class="col s12 m6">
                        <i v-if="isCollection" class="material-icons medium left">store</i>
                        <h5 v-if="isCollection">Odbiór w sklepie</h5>
                        <h5 v-else>Dostawa Kurier</h5>
                        <p>{{ order.deliveryAddress.name }}</p>
                        <p>{{ order.deliveryAddress.street }}</p>
                        <p>
                            {{ order.deliveryAddress.zipCode }}
                            {{ order.deliveryAddress.city }}
                        </p>
                        <p>{{ order.deliveryAddress.phone }}</p>
                    </div>

                    <div v-if="order.deliveryAddress.fv" class="col s12 m6">
                        <h5>Dane do faktury</h5>
                        <p>
                            {{ order.deliveryAddress.fvName }}
                            {{ order.deliveryAddress.fvFirma }}
                        </p>
                        <p>{{ order.deliveryAddress.fvStreet }}</p>
                        <p>
                            {{ order.deliveryAddress.fvZipCode }}
                            {{ order.deliveryAddress.fvCity }}
                        </p>
                        <p>{{ order.deliveryAddress.nip }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!--  modal produktu -->
        <item-quick-view></item-quick-view>
        <user-quick-view></user-quick-view>
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import axios from "axios";
import OrderChangeStatusPaid from "@/components/OrderChangeStatusPaid";
import OrderChangeStatusCancelled from "@/components/OrderChangeStatusCancelled";
import OrderChangeStatusDispatched from "@/components/OrderChangeStatusDispatched";
import admin from "@/admin";
import EventBus from "@/event-bus";
import ItemQuickView from "@/components/ItemQuickView.vue";
import UserQuickView from "@/components/UserQuickView";

export default {
    props: {
        orderId: String,
    },

    components: {
        orderChangeStatusPaid: OrderChangeStatusPaid,
        orderChangeStatusCancelled: OrderChangeStatusCancelled,
        orderChangeStatusDispatched: OrderChangeStatusDispatched,
        itemQuickView: ItemQuickView,
        userQuickView: UserQuickView,
    },

    data() {
        return {
            order: null,
            itemsFromOrder: null,
            env: process.env,
        };
    },

    computed: {
        OrderStatusMeta() {
            let os = admin.orderStatuses[this.order.status];
            if (!os) os = admin.orderStatuses.UNKNOWN;
            return os;
        },

        isCollection: function () {
            return (
                this.order &&
                this.order.checkoutInfo &&
                this.order.checkoutInfo.deliveryCost &&
                this.order.checkoutInfo.deliveryCost.meta &&
                this.order.checkoutInfo.deliveryCost.meta.type == "collection"
            );
        },
    },

    watch: {
        order: function (after, before) {
            if (after) {
                //fetch collection

                let query =
                    "byItemId:0000 " + after.items.map((item) => item.id).join(" ");

                axios
                    .get(process.env.VUE_APP_API + "/ui/collections.json", {
                        params: {
                            q: query,
                            limit: 900,
                            ref: "admin:order",
                        },
                    })
                    .then((axiosResponse) => {
                        let allitemsArray = axiosResponse.data.collections.byItemId;
                        console.log("axiosResponse ok");

                        var mapped = allitemsArray.reduce(function (map, item) {
                            map[item.id] = item;
                            return map;
                        }, {});
                        this.itemsFromOrder = mapped;
                    })
                    .catch((error) => {
                        console.error("collections: ", error);
                    });
            }
        },
    },

    methods: {
        //modal produktu
        revealModalProdctDetailsQuickView: function (itemId) {
            EventBus.$emit("OPEN_PRODUCT_QUICK_VIEW", {itemId: itemId});
        },

        revealModalUserQuickView: (id) => {
            EventBus.$emit("OPEN_USER_QUICK_VIEW", {userId: id});
        },

        toggleProcessingStatus: function (status) {
            let orderUpdateStatusCallable = firebase
                .functions()
                .httpsCallable("orderUpdateProcessingStatus");

            orderUpdateStatusCallable({
                orderId: this.orderId,
                status: status,
            }).then((result) => {
                setTimeout(() => {
                    admin.toastInfo(
                        status == "collecting_start"
                            ? "Obsługa rozpoczęta"
                            : status == "collecting_completed"
                                ? "Wystawione dla kuriera"
                                : "Anulowano ok"
                    );
                }, 555);
            });

            console.log("tps", status);
        },
    },

    created: function () {
        return firebase
            .firestore()
            .collection("orders")
            .doc(this.orderId)
            .onSnapshot((doc) => {
                this.order = doc.data();

                // this.processingInProgress =
            });
    },

    mounted: function () {
    },
};
</script>

<style scoped>
.summary {
    font-weight: bolder;
}

.superSummary {
    font-size: 1.5rem;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}
</style>
