<template>
  <div class="container">
    <h1>Aktualizacja oferty</h1>
    <p>Wybierz lokalny plik aktualizujący ofertę.</p>

    <div class="section">
      <form v-on:submit.prevent ref="uploadForm">
        <div class="file-field input-field">
          <div class="btn" v-bind:class="{ disabled: uploadInProgress }">
            <span>Plik aktualizacji</span>
            <input
              type="file"
              id="file"
              ref="file"
              v-on:change="handleFileUpload()"
            />
          </div>
          <div class="file-path-wrapper">
            <input class="file-path validate" type="text" />
          </div>
        </div>
      </form>
    </div>

    <div class="section">
      <template v-if="!uploadDiff">
        <upload-docs></upload-docs>
      </template>

      <template v-if="uploadDiff && uploadDiff.uploadType == 'PRODUCTS'">
        <diff-product :uploadDiff="uploadDiff"></diff-product>
      </template>

      <template
        v-if="uploadDiff && uploadDiff.uploadType == 'PRODUCT_DESCRIPTION'"
      >
        <diff-description :uploadDiff="uploadDiff"></diff-description>
      </template>

      <template v-if="uploadDiff && uploadDiff.uploadType == 'APP_CONFIG'">
        <diff-app-config :uploadDiff="uploadDiff"></diff-app-config>
      </template>

      <template v-if="uploadDiff && uploadDiff.uploadType == 'ITEM_IMAGE'">
        <diff-item-image :uploadDiff="uploadDiff"></diff-item-image>
      </template>

      <template v-if="uploadDiff && uploadDiff.uploadType == 'STOCKS'">
        <diff-stock :uploadDiff="uploadDiff"></diff-stock>
      </template>
    </div>

    <div class="fixed-action-btn" v-show="uploadDiff && !commitInProgress">
      <a class="btn-floating btn-large blue">
        <i class="large material-icons">menu</i>
      </a>
      <ul>
        <li>
          <a
            @click="commitUpload()"
            class="btn-floating green btn-large waves-effect waves-light"
          >
            <i class="material-icons large">save</i>
          </a>
        </li>
        <li>
          <a
            @click="cancelUpload()"
            class="btn-floating red waves-effect waves-light"
          >
            <i class="material-icons">cancel</i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DiffProduct from "./UploadDiffProducts.vue";
import DiffDescription from "./UploadDiffDescription.vue";
import DiffAppConfig from "./UploadDiffAppConfig.vue";
import DiffItemImage from "./UploadDiffItemImage.vue";
import DiffStocks from "./UploadStocks.vue";
import UploadDocs from "./UploadDocs.vue";
import M from "materialize-css";
import admin from "@/admin";

export default {
  components: {
    diffProduct: DiffProduct,
    diffDescription: DiffDescription,
    diffAppConfig: DiffAppConfig,
    diffItemImage: DiffItemImage,
    diffStock: DiffStocks,
    uploadDocs: UploadDocs,
  },

  data() {
    return {
      file: "",
      uploadDiff: null,
      showSelection: "ALL",
      uploadInProgress: false,
      commitInProgress: false,
      env: process.env,
    };
  },

  methods: {
    handleFileUpload() {
      this.uploadDiff = null;
      this.file = this.$refs.file.files[0];
      console.log(this.file);

      if (
        process.env.VUE_APP_DISABLE_IMAGES_UPLOAD === "true" &&
        this.file.name.endsWith(".webp")
      ) {
        admin.toastError("Dodawanie zdjęć produktów wyłączone");
        return;
      }

      this.submitFile();
    },

    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.uploadInProgress = true;

      axios
        .post(process.env.VUE_APP_API + "/api/apiProducts/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.uploadDiff = response.data;
          console.log("SUCCESS!!");
        })
        .catch((error) => {
          console.log("FAILURE!! " + error);
          this.clearForm();
        })
        .finally(() => {
          this.uploadInProgress = false;
        });
    },

    commitUpload() {
      this.commitInProgress = true;
      const formData = new FormData();
      formData.append("uploadId", this.uploadDiff.uploadId);
      // formData.append('dryRun', '1');

      axios
        .post(process.env.VUE_APP_API + "/api/apiProducts/commit", formData)
        .then(() => {
          console.log("commit SUCCESS!!");
          var html =
            "Zmiany zapisano&nbsp;<span class='yellow-text'>" +
            this.uploadDiff.uploadLabel +
            "</span>";
          M.toast({ html: html });

          this.clearForm();
          this.commitInProgress = false;
        })
        .catch(function () {
          console.log("FAILURE!!");
          this.commitInProgress = false;
        });
    },

    cancelUpload() {
      this.clearForm();
    },

    clearForm() {
      this.uploadDiff = null;
      this.file = null;
      this.$refs.uploadForm.reset();
    },
  },

  mounted() {
    console.log("mounted");
    var elems = document.querySelectorAll(".fixed-action-btn");
    M.FloatingActionButton.init(elems, {
      hoverEnabled: false,
    });
  },
};
</script>

<style scoped>
</style>
