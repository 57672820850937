<template>
  <div>
    <button @click="getAllOrders">zamówienia</button>
    <pre>{{ ordersCsv }}</pre>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import firebase from 'firebase/app'
import "firebase/firestore";
import admin from "@/admin";

export default {
  data() {
    return {
      ordersCsv: null,
    };
  },

  methods: {
    getAllOrders() {
      let allOrdersCallable = firebase
        .functions()
        .httpsCallable("getAllOrders");

      allOrdersCallable({}).then((res) => {
        console.log(res.data);

        this.ordersCsv =
          "id,numer,userEmail,data,rodo\n" +
          res.data
            .map((o) => {
              let choice =
                o.choices && o.choices.akceptacjaPrzetwarzaniaDanych
                  ? o.choices.akceptacjaPrzetwarzaniaDanych
                  : "";

              let date = new Date(o.createDateAsSeconds * 1000).toLocaleDateString("pl-PL");

              return (
                o.id +
                "," +
                o.orderNumber +
                "," +
                o.userEmail +
                "," +
                date +
                "," +
                choice
              );
            })
            .join("\n");
      });
    },

    created() {},
  },
};
</script>