<template>
  <secure-main v-if="this.$store.getters.loggedIn" :errorMessageObj="errorMessageObj"></secure-main>
  <login v-else></login>
  <!-- tutaj kiedy wylogowany - jako komponent -->
</template>
<script>
import axios from "axios";
import auth from "./auth";
import Main from "./Main.vue";
import Login from "@/views/Login";

export default {
  components: {
    secureMain: Main,
    login: Login
  },
  data() {
    return {
      errorModal: null,
      errorMessageObj: {}
    };
  },

  computed: {},

  methods: {
    handle401: function() {
      this.$store.dispatch("setLoggedOut");
    },
    handleError: function(errorMessage) {
      console.log("handling error", errorMessage);
      this.errorMessageObj = errorMessage;
    }
  },

  created() {
    axios.interceptors.request.use(
      config => {
        // store.commit('loader/START_LOADING');
        this.$store.dispatch("startProgress", {});
        return config;
      },
      error => {
        // store.commit('loader/FINISH_LOADING');
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        this.$store.dispatch("endProgress", {});
        // Do something with response data
        return response;
      },
      error => {
        this.$store.dispatch("endProgress", {});

        if (error.response.status == 401) {
          console.log("axios interceptor for 401...");
          this.handle401();
        } else this.handleError(error.response.data);
        return Promise.reject(error);
      }
    );

    auth.init({
      refreshAuthTokenSuccessCallback: authToken => {
        this.$store.dispatch("setLoggedIn");
        this.$store.dispatch("setAuthToken", authToken);
      },

      refreshAuthTokenFailedCallback: () => {
        this.handle401();
      },

      refreshExchangeTokenFailedCallback: () => {
        this.handle401();
      }
    });
  },

  mounted() {
    // var elems = document.querySelectorAll(".modal");
    // M.Modal.init(elems, {});
    // this.errorModal = M.Modal.getInstance(document.querySelector(".modal"));
  }
};
</script>

<style>
body {
  /* display: flex; */
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}
</style>
