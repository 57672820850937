import Vue from 'vue'
import Router from 'vue-router'
import Order from './views/Order.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    },
    {
      path: '/oferta/upload',
      name: 'upload',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/components/Upload.vue')
    },
    {
      path: '/reviews',
      name: 'reviews',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Reviews.vue')
    },
    {
      path: '/reviews/:itemId',
      name: 'reviewsForItem',
      component: () => import(/* webpackChunkName: "about" */ '@/views/ReviewsForItem.vue'),
      props: true
    },
    {
      path: '/order/:orderId',
      name: 'order',
      component: Order,
      props: true
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Orders.vue')
    },
    {
      path: '/banners',
      name: 'banners',
      component: () => import(/* webpackChunkName: "banners" */ '@/views/Banners.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Users.vue')
    },
    {
      path: '/report',
      name: 'reportsHome',
      component: () => import(/* webpackChunkName: "report" */ '@/views/report/Home.vue')
    },
    {
      path: '/report/items/:reportId',
      name: 'reportItems',
      component: () => import(/* webpackChunkName: "report" */ '@/views/report/ReportItems.vue')
    },
    {
      path: '/report/orders/:reportId',
      name: 'reportOrders',
      component: () => import(/* webpackChunkName: "report" */ '@/views/report/Orders.vue')
    },
    {
      path: '/report/orders/byProduct',
      name: 'reportOrdersByProduct',
      component: () => import(/* webpackChunkName: "report" */ '@/views/report/OrdersByProduct.vue')
    },
    {
      path: '/report/orders/byProductOverTime/:initialItemId?',
      name: 'reportOrdersByProductOverTime',
      component: () => import(/* webpackChunkName: "report" */ '@/views/report/OrdersByProductOverTime.vue'),
      props: true
    },
    {
      path: '/report/productsWithNoOrders',
      name: 'productsWithNoOrders',
      component: () => import(/* webpackChunkName: "report" */ '@/views/report/ProductsWithNoOrders.vue'),
      props: true
    },
    {
      path: '/report/swProductsByDemand',
      name: 'swProductsByDemand',
      component: () => import(/* webpackChunkName: "report" */ '@/views/report/SwProductsByDemand.vue'),
      props: true
    },
    {
      path: '/report/stockEvents',
      name: 'stockEvents',
      component: () => import(/* webpackChunkName: "report" */ '@/views/report/StockEvents.vue'),
      props: true
    },
    {
      path: '/admin/misc',
      name: 'admin-misc',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Misc.vue')

    },
    {
      path: '/debug',
      name: 'debug',
      component: () => import(/* webpackChunkName: "debug" */ '@/views/Debug.vue')

    },
  ]
})
