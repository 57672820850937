<template>
  <div class="container">
    <h1 v-if="reportId == 'ordersMonthly'">Sprzedaż po miesiącach</h1>
    <h1 v-else>{{ selectedReportConfig.title }}: {{ filterLabel }}</h1>
    <div class="row" v-show="reportId != 'ordersMonthly'">
      <!-- Dropdown Trigger -->
      <a
        id="orders-dropdown-trigger"
        class="dropdown-trigger btn"
        href="#"
        data-target="orders-dropdown1"
        style="width: 220px"
        >Wybierz okres</a
      >

      <!-- Dropdown Structure -->
      <ul id="orders-dropdown1" class="dropdown-content">
        <li>
          <a
            href
            @click.prevent="
              filterByDate = 'last30Days';
              filterLabel = 'ostatnie 30 dni';
            "
            >ostatnie 30 dni</a
          >
        </li>
        <li>
          <a
            href
            @click.prevent="
              filterByDate = 'last3Month';
              filterLabel = 'ostatnie 3 miesiące';
            "
            >ostatnie 3 miesiące</a
          >
        </li>
        <li>
          <a
            href
            @click.prevent="
              filterByDate = 'last6Month';
              filterLabel = 'ostatnie 6 miesięcy';
            "
            >ostatnie 6 miesięcy</a
          >
        </li>
        <li>
          <a
            href
            @click.prevent="
              filterByDate = 'last12Month';
              filterLabel = 'ostatnie 12 miesięcy';
            "
            >ostatnie 12 miesięcy</a
          >
        </li>
        <li class="divider" tabindex="-1"></li>
        <li>
          <a
            href
            @click.prevent="
              filterByDate = 'thisMonth';
              filterLabel = 'ten miesiąc';
            "
            >ten miesiąc</a
          >
        </li>
        <li>
          <a
            href
            @click.prevent="
              filterByDate = 'previousMonth';
              filterLabel = 'poprzedni miesiąc';
            "
            >poprzedni miesiąc</a
          >
        </li>
        <li>
          <a
            href
            @click.prevent="
              filterByDate = 'thisYear';
              filterLabel = 'ten rok';
            "
            >ten rok</a
          >
        </li>
        <li>
          <a
            href
            @click.prevent="
              filterByDate = 'previousYear';
              filterLabel = 'poprzedni rok';
            "
            >poprzedni rok</a
          >
        </li>
        <li class="divider" tabindex="-1"></li>
        <li>
          <a
            href
            @click.prevent="
              filterByDate = 'all';
              filterLabel = 'wszystko';
            "
            >wszystko</a
          >
        </li>
      </ul>
      <div v-if="false" class="col s12">
        Okres:
        <br />
        <label>
          <input
            name="filterByDate"
            type="radio"
            value="last30Days"
            v-model="filterByDate"
            checked
          />
          <span style="padding-right: 20px">ostatnie 30 dni</span>
        </label>

        <label>
          <input
            name="filterByDate"
            type="radio"
            value="last3Month"
            v-model="filterByDate"
          />
          <span style="padding-right: 20px">ostatnie 3 miesiące</span>
        </label>

        <label>
          <input
            name="filterByDate"
            type="radio"
            value="last6Month"
            v-model="filterByDate"
          />
          <span style="padding-right: 20px">ostatnie 6 miesięcy</span>
        </label>

        <label>
          <input
            name="filterByDate"
            type="radio"
            value="last12Month"
            v-model="filterByDate"
          />
          <span style="padding-right: 20px">ostatnie 12 miesięcy</span>
        </label>
        <br />
        <label>
          <input
            name="filterByDate"
            type="radio"
            value="thisMonth"
            v-model="filterByDate"
          />
          <span style="padding-right: 20px">ten miesiąc</span>
        </label>

        <label>
          <input
            name="filterByDate"
            type="radio"
            value="previousMonth"
            v-model="filterByDate"
          />
          <span style="padding-right: 20px">poprzedni miesiąc</span>
        </label>
        <br />
        <label>
          <input
            name="filterByDate"
            type="radio"
            value="thisYear"
            v-model="filterByDate"
          />
          <span style="padding-right: 20px">ten rok</span>
        </label>

        <label>
          <input
            name="filterByDate"
            type="radio"
            value="previousYear"
            v-model="filterByDate"
          />
          <span style="padding-right: 20px">poprzedni rok</span>
        </label>

        <label>
          <input
            name="filterByDate"
            type="radio"
            value="all"
            v-model="filterByDate"
          />
          <span style="padding-right: 20px">Wszystko</span>
        </label>
      </div>
    </div>
    <div class="card">
      <canvas id="myChart"></canvas>
    </div>
    <div class="card">
      <table
        id="ordersTable"
        class="row-border hover order-column"
        cellspacing="0"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th
              v-for="index in this.selectedReportConfig.columnsConfig.length"
              :key="index"
            >
              C{{ index }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <span id="filterByDate"></span>
    <span id="reportId"></span>
  </div>
</template>

<script>
import reportConfig from "./reportConfig";
import EventBus from "@/event-bus";
import firebase from 'firebase/app'
import Chart from "chart.js";
import NProgress from "nprogress";

export default {
  props: {
    tableColumns: null,
    reportTitle: String,
  },

  components: {},

  data() {
    return {
      reportId: this.$route.params.reportId,
      filterByDate: null,
      filterLabel: "ten miesiąc",
      refreshThread: null,
      dropdownInstance: null,
    };
  },

  computed: {
    selectedReportConfig() {
      return reportConfig.columnsConfig(null, this.reportId, this);
    },
  },

  watch: {
    filterByDate: function (after, before) {
      $("#filterByDate").val(after); // przekazanie wartosci do scope widzianego przez jQuery
      this.initializeTable();
      this.fetchReportData();
    },
  },

  methods: {
    initializeTable: function () {
      $("#reportId").val(this.reportId);

      //https://datatables.net/examples/ajax/objects.html
      $("#ordersTable").DataTable().destroy();

      var table = $("#ordersTable").DataTable({
        ajax: function (data, callback, settings) {
          let orderUpdateStatusCallable = firebase
            .functions()
            .httpsCallable("bqQueryAllOrders");

          var filterByDate = $("#filterByDate").val();
          var reportId = $("#reportId").val();
          orderUpdateStatusCallable({
            filterByDate: reportId == "ordersMonthly" ? "all" : filterByDate,
            q: reportId,
          })
            .then((result) => {
              callback({
                data: result.data,
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        },
        columns: this.selectedReportConfig.columnsConfig,

        language: {
          search: "",
          searchPlaceholder: "Szukaj po dacie, kwocie itp...",
          info: "Pokazywane wiersze _START_ - _END_ z _TOTAL_",
        },
        order: [0, "desc"],
        dom: 'ft<"footer-wrapper"l<"paging-info"ip>B>',
        scrollY: "35vh",
        // scrollCollapse: true,
        pagingType: "full",
        paging: false,
        buttons: ["copy", "csv", "excel", "pdf", "print"],
        drawCallback: function (settings) {
          var api = this.api();

          // Add waves to pagination buttons
          $(api.table().container()).find(".paginate_button");

          api.table().columns.adjust();
        },
      });
    },

    initializeChart: () => {
      var ctx = document.getElementById("myChart").getContext("2d");
      var chart = new Chart(ctx, {
        type: "bar",

        data: {
          datasets: [
            {
              yAxisID: "y-axis-1",
              label: "Wartość sprzedaży zł",
              // borderColor: "rgb(255, 99, 132)",
              backgroundColor: Chart.helpers
                .color("rgb(54, 162, 235)")
                .alpha(0.5)
                .rgbString(),
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 1,
              // data: [0, 10, 5, 2, 20, 30, 45],
              fill: true,
              lineTension: 0, //zaokraglanie - 0=kanciate
              spanGaps: true,
              // fill: false,
              pointRadius: 2,
              pointHoverRadius: 10,
            },
            {
              yAxisID: "y-axis-2",
              type: "line",
              label: "Liczba zamówień",
              // borderColor: "rgb(255, 99, 132)",
              // backgroundColor: "rgb(255, 99, 132)",
              // borderColor: "rgb(54, 162, 235)",
              fill: false,
              // lineTension: 0, //zaokraglanie - 0=kanciate
              cubicInterpolationMode: "monotone",
              spanGaps: true,
              // fill: false,
              pointRadius: 2,
              pointHoverRadius: 10,
            },
          ],
        },

        // Configuration options go here
        options: {
          scales: {
            xAxes: [
              {
                // type: 'time',
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 10,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Sprzedaż zł",
                },
                position: "left",
                id: "y-axis-1",
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 4,
                },
              },
              {
                type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                display: true,
                position: "right",
                id: "y-axis-2",
                gridLines: {
                  drawOnChartArea: false,
                },
                ticks: {
                  // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
                  min: 0,
                  // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
                  suggestedMax: 10,
                  maxTicksLimit: 6,
                },
              },
            ],
          },
          tooltips: {
            enabled: true,
            // mode: "nearest",
            // intersect: false,

            position: "average",
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (tooltipItem, data) {
                if (tooltipItem.datasetIndex == 0)
                  return Number(tooltipItem.yLabel).toFixed(2) + " zł";
                else if (tooltipItem.datasetIndex == 1)
                  return "zamówień: " + tooltipItem.yLabel;
                else return "...";
              },
            },
          },
        },
      });

      return chart;
    },

    initializeDropDown: () => {
      var elems = document.querySelector("#orders-dropdown-trigger");
      return M.Dropdown.init(elems, { hover: false });
    },

    fetchReportData: function () {
      NProgress.start();

      let orderUpdateStatusCallable = firebase
        .functions()
        .httpsCallable("bqQueryAllOrders");

      var filterByDate = $("#filterByDate").val();
      var reportId = $("#reportId").val();
      orderUpdateStatusCallable({
        filterByDate: reportId == "ordersMonthly" ? "all" : filterByDate,
        q: reportId,
      })
        .then((result) => {
          $("body").trigger("szukajka:orders:reportDataUpdated", [
            { data: result.data },
          ]);
        })
        .catch((err) => {
          console.log("fetchReportData", err);
        })
        .finally(() => {
          NProgress.done();
        });
    },
  },

  mounted: function () {
    var chart = this.initializeChart();

    $("body").on("szukajka:orders:reportDataUpdated", (event, payload) => {
      console.log("szukajka:orders:reportDataUpdated", payload, this.reportId);
      var labels = payload.data.map((row) => row.dateSeq);
      // chart.config.type = "bar";

      if (this.reportId == "ordersMonthly") {
        labels = payload.data.map(
          (row) => row.year + "-" + row.month.toString().padStart(2, "00")
        );
        // chart.config.type = "bar";
      }

      const ordersTotal = payload.data.map((row) => row.itemsTotal);
      const ordersCount = payload.data.map((row) => row.cnt);

      chart.data.labels = labels;
      chart.data.datasets[0].data = ordersTotal;
      chart.data.datasets[1].data = ordersCount;
      chart.update();
    });
    $("#filterByDate").val("thisMonth");
    this.initializeTable();
    this.fetchReportData();

    this.dropdownInstance = this.initializeDropDown();

    this.refreshThread = setInterval(() => {
      this.fetchReportData();
    }, 10 * 60 * 1000);
  },

  beforeRouteUpdate(to, from, next) {
    console.log(`beforeRouteUpdate: ${to.params.reportId} `);
    this.reportId = to.params.reportId;
    this.initializeTable();
    this.fetchReportData();
    next();
  },

  destroyed: function () {
    $("body").off("szukajka:orders:reportDataUpdated");
    clearInterval(this.refreshThread);
  },
};
</script>

<style scoped>
.container {
  width: 90%;
}
</style>
