<template>
  <div>
    <h3>Aktualizacja opisu - przegląd zmian</h3>  
    <div class="card-panel grey-text text-darken-1">
      <h4>{{uploadDiff.item.title}} (id: {{uploadDiff.item.id}})</h4> 
      <div class="divider"></div>  
      <div class="preview flow-text" v-html="uploadDiff.newDescriptionAsHtml">
    </div> 
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uploadDiff: Object
  },

  data() {
    return {};
  },

  methods: {}
};
</script>

<style scoped>
ul .diff {
  list-style-type: none;
}
ins {
  background-color: rgb(230, 255, 230);
  text-decoration: none;
}

del {
  background-color: rgb(255, 230, 230);
}

.preview h2 {
  font-size: 2rem;
}
</style>
