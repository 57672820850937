<template>
    <div class="container center login-panel">
        <div class="card-panel">
            <div class="section">
                <h5>{{ appName }}</h5>
                <h1>Logowanie</h1>
            </div>
            <div v-if="isAuthDisabled()">
                <p>Tymczasowo bez podawania loginu/hasła</p>
            </div>
            <div v-else class="row">
                <div class="input-field col s6">
                    <input id="first_name" type="text" class="validate" v-model="formUsername"/>
                    <label for="first_name">Login</label>
                </div>
                <div class="input-field col s6">
                    <input id="last_name" type="password" class="validate" v-model="formPassword"/>
                    <label for="last_name">Hasło</label>
                </div>
            </div>
            <p v-if="feedback" class="red-text">{{ feedback }}</p>
            <a class="btn" v-bind:class="{disabled: inProgress}" @click="refreshExchangeToken">
                <i class="material-icons right">lock_open</i>Loguj
            </a>
        </div>
    </div>
</template>

<script>
import auth from "../auth";

export default {
    components: {},
    data() {
        return {
            inProgress: false,
            formUsername: null,
            formPassword: null,
            feedback: null,
            appName: process.env.VUE_APP_INSTANCE_NAME
        };
    },
    methods: {
        refreshExchangeToken() {
            this.inProgress = true;

            var uName = this.formUsername;
            var passwd = this.formPassword;

            if (this.isAuthDisabled()) {
                uName = process.env.VUE_APP_ADMIN_USERNAME;
                passwd = process.env.VUE_APP_ADMIN_PASSWORD;
            }

            auth
                .refreshExchangeToken(uName, passwd)
                .then(result => {
                    console.log("login ok " + result);
                    this.$router.push({name: "orders"});
                })
                .catch(() => {
                    console.log("Login error");
                    this.feedback = "Logowanie nie powiodło się. Spróbuj ponownie.";
                })
                .finally(() => {
                    setTimeout(
                        function () {
                            this.inProgress = false;
                            this.feedback = null;
                        }.bind(this),
                        4444
                    );
                });
        },

        isAuthDisabled() {
            return (
                process.env.VUE_APP_ADMIN_PASSWORD != null &&
                process.env.VUE_APP_ADMIN_USERNAME != null
            );
        }
    },

    created() {
        if (window.location.pathname !== "/") window.location = "/";
    }
};
</script>
<style scoped>
.login-panel {
    margin-top: 200px;
}
</style>
