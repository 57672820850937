import M from "materialize-css";

const orderStatuses = {
    UNPAID: {
        code: 'UNPAID',
        cssClass: 'red-text text-lighten-2',
        cssButtonClass: 'red lighten-2',
    },
    PAID: {
        code: 'PAID',
        cssClass: 'green-text text-lighten-2',
        cssButtonClass: 'green lighten-2'
    },
    CANCELLED: {
        code: 'CANCELLED',
        cssClass: 'grey-text',
        cssButtonClass: 'grey'
    },
    DISPATCHED: {
        code: 'DISPATCHED',
        cssClass: 'brown-text text-lighten-2',
        cssButtonClass: 'brown lighten-1'
    },
    UNKNOWN: {
        code: 'UNKNOWN',
        cssClass: 'gray-text gray-lighten-2',
        cssButtonClass: 'gray lighten-2'
    }
}

const seasons = {
    SUMMER: 'summer',
    WINTER: 'winter',
    SPRING: 'spring',
    AUTUMN: 'autumn'
}

let toastInfo = function (html) {
    M.toast({ html: html, classes: "grey darken-1" });
}


let toastError = function (html) {
    M.toast({ html: html, classes: "red darken-1" });
}

const plainToHtml = function (raw) {

    if (raw == null)
        return ''

    var html = raw
        .trim()
        .replace(/\n/g, "<br />")
        .replace(/„/g, "")
        .replace(/”/g, "");
    return "„" + html + "”";
}


export default { orderStatuses, seasons, toastInfo, toastError, plainToHtml }