<template>
  <div style="display: inline-block">
    <a
      class="btn waves-effect waves-light"
      :class="OrderStatusDispatched.cssButtonClass"
      @click="openSetDispatchedDialog"
    >
      Oznacz wysłane
    </a>

    <div class="modal orderDetailsModal" id="modalSetOrderDispatched">
      <div class="modal-content" v-if="order">
        <h4 :class="OrderStatusDispatched.cssClass">
          {{ order.orderNumber }} - Wysłane
        </h4>
        <p>Zaznaczyć jako wysłane?</p>
        <p>
          <label>
            <input
              type="checkbox"
              class="indeterminate-checkbox"
              checked="checked"
              v-model="notifyCustomer"
            />
            <span>Powiadom Klienta</span>
          </label>
        </p>
        <p class="red-text text-darken-2" v-if="operationFeedback">
          Wystąpił problem: {{ $t(operationFeedback) }}
        </p>
      </div>
      <div class="modal-footer">
        {{ order.id }}
        <a
          class="waves-effect btn-flat modal-close"
          :class="{ disabled: inProgress }"
          >Anuluj</a
        >&nbsp;
        <a
          class="waves-effect waves-teal btn"
          :class="{ disabled: inProgress }"
          @click="setOrderAsDispatched"
          >Zapisz</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import firebase from "firebase/app";
import functions from "firebase/functions";
import admin from "@/admin";
import NProgress from "nprogress";

export default {
  props: {
    order: Object,
    orderId: null,
  },

  data() {
    return {
      inProgress: false,
      operationFeedback: null,
      notifyCustomer: false,
    };
  },

  computed: {
    OrderStatusDispatched() {
      return admin.orderStatuses.DISPATCHED;
    },
  },

  methods: {
    openSetDispatchedDialog: function () {
      this.modalSetOrderDispatched().open();
    },

    setOrderAsDispatched: function () {
      NProgress.start();
      this.inProgress = true;
      this.operationFeedback = null;

      let orderUpdateStatusCallable = firebase
        .functions()
        .httpsCallable("orderUpdateStatus");

      orderUpdateStatusCallable({
        orderId: this.orderId,
        status: admin.orderStatuses.DISPATCHED.code,
        notify: this.notifyCustomer,
      })
        .then((result) => {
          this.modalSetOrderDispatched().close();
          setTimeout(() => {
            admin.toastInfo("Gotowe.");
          }, 555);
        })
        .catch((err) => {
          this.operationFeedback = err.message;
        })
        .finally(() => {
          this.inProgress = false;
          NProgress.done();
        });
    },

    modalSetOrderDispatched: function () {
      return M.Modal.getInstance(
        document.querySelector("#modalSetOrderDispatched")
      );
    },
  },

  created: function () {},

  mounted: function () {
    var elems = document.querySelectorAll(".orderDetailsModal");
    M.Modal.init(elems, {
      onCloseEnd: () => {
        this.operationFeedback = null;
      },
    });
  },
};
</script>

<style scoped>
</style>
