<template>
  <div class="container">
    <h1>Klienci</h1>

    <div class="card">
      <table
        id="usersTable"
        class="row-border hover order-column"
        cellspacing="0"
        style="width: 100%"
      ></table>
    </div>
    <!--  modal karty usera -->
    <user-quick-view></user-quick-view>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import firebase from 'firebase/app'
import "firebase/firestore";
import NProgress from "nprogress";
import UserQuickView from "@/components/UserQuickView";

export default {
  props: {},

  components: {
    userQuickView: UserQuickView,
  },

  data() {
    return {
      usersTable: null,
      users: [],
    };
  },

  computed: {},

  watch: {},

  methods: {
    initializeTable: function () {
      //https://datatables.net/examples/ajax/objects.html
      // $("#usersTable").DataTable().destroy();

      var table = $("#usersTable").DataTable({
        data: [],
        columns: [
          {
            title: "Email",
            data: "email",
            render: function (data, type, row, meta) {
              // return `<a href="javascript:rowClicked('${row.id}')">${data}</a>`;
              return `<a href="javascript:$('body').trigger('szukajka:usersTable:rowClicked', [{ userId: '${row.id}' }]);void(0);">${data}</a>`;
            },
          },
          {
            title: "Nazwa, Miasto",
            data: "name",
            // className: "dt-body-center",
            render: (data, type, row, meta) => {
              return `${row.name}, ${row.city}`;
            },
          },
          {
            title: "Zamówienia suma",
            data: "totalItems",
            render: (data, type, row, meta) => {
              if (type === "display" || type === "filter")
                return this.$i18n.n(data, "currency");
              else return data;
            },
            className: "dt-body-right",
          },
          {
            title: "Zamówienia liczba",
            data: "ordersCount",
            className: "dt-body-center",
          },
          {
            title: "Zamówienie średnio",
            data: "ordersAvg",
            render: (data, type, row, meta) => {
              if (type === "display" || type === "filter")
                return this.$i18n.n(data, "currency");
              else return data;
            },
            className: "dt-body-right",
          },
          {
            title: "rodo",
            data: "rodo",
            render: (data, type, row, meta) => {
              if (type === "display" || type === "filter")
                //https://datatables.net/manual/data/orthogonal-data
                return data == "1" ? "zgoda" : "odmowa";
              else return data;
            },
          },
        ],
        language: {
          search: "",
          searchPlaceholder: "Szukaj po nazwie, kodzie itp...",
          info: "Pokazywane wiersze _START_ - _END_ z _TOTAL_",
        },
        order: [0, "asc"],
        dom: 'ft<"footer-wrapper"l<"paging-info"ip>B>',
        scrollY: "35vh",
        // scrollCollapse: true,
        pagingType: "full",
        paging: false,
        buttons: ["copy", "csv", "excel", "pdf", "print"],
        drawCallback: function (settings) {
          var api = this.api();

          // Add waves to pagination buttons
          $(api.table().container()).find(".paginate_button");

          api.table().columns.adjust();
        },
      });

      return table;
    },

    fetchUsers: function () {
      NProgress.start();

      let customQueryCallable = firebase
        .functions()
        .httpsCallable("bqCustomQuery");

      customQueryCallable({
        q: "users",
      })
        .then((result) => {
          console.log("res", result.data);
          this.usersTable.clear().rows.add(result.data).draw();
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          NProgress.done();
        });

      var data = [
        { email: "22Tiger Nixon", name: "2System Architect", aa: "2Edinburgh" },
        {
          email: "3Tiger Nixon",
          name: "3 System Architect",
          aa: "3 Edinburgh",
        },
        {
          email: "4 Tiger Nixon",
          name: "4 System Architect",
          aa: "4 Edinburgh",
        },
      ];
      // this.usersTable.clear().draw();
      // this.usersTable.rows.add(data); // Add new data
      // this.usersTable.columns.adjust().draw();

      // this.usersTable.clear().rows.add(data).draw();
    },
  },

  mounted: function () {
    this.usersTable = this.initializeTable();
    this.fetchUsers();

    //mapowanie z eventu jQuery na event vue
    $("body").on("szukajka:usersTable:rowClicked", (event, data) => {
      console.log("clicked", data.userId);
      EventBus.$emit("OPEN_USER_QUICK_VIEW", { userId: data.userId });
    });
  },

  destroyed: function () {
    $("body").off("szukajka:usersTable:rowClicked");
  },
};
</script>

<style scoped>
.container {
  width: 90%;
}
</style>
