<template>
  <!--  modal produktu -->
  <div id="modalProdctDetailQuickView" class="modal bottom-sheet">
    <div class="modal-content" v-if="modalProdctDetails">
      <span>
        id: {{ modalProdctDetails.id }}
        <a
          v-bind:href="`${env.VUE_APP_UI}${
            env.VUE_APP_EXT_LINK_PRODUCT
              ? require('printf')(
                  env.VUE_APP_EXT_LINK_PRODUCT,
                  modalProdctDetails.prettyId
                )
              : '/i_' + modalProdctDetails.prettyId
          }`"
          target="_new"
        >
          <i class="material-icons">launch</i>
        </a>
      </span>
      <img
        v-if="
          modalProdctDetails.imgsBySize && modalProdctDetails.imgsBySize['150']
        "
        v-bind:src="`${env.VUE_APP_API}/ii/${
          modalProdctDetails.id
        }/150/${modalProdctDetails.imgsBySize['150'].slice(0, 1)}`"
        style="float: right"
      />
      <h4>{{ modalProdctDetails.name }}</h4>

      <h5 v-if="modalProdctDetails.stock && modalProdctDetails.stock.price">
        {{ $n(modalProdctDetails.stock.price, "currency") }}
      </h5>

      <span
        v-if="modalProdctDetails.stock && modalProdctDetails.stock.quantity"
      >
        Stock: {{ modalProdctDetails.stock.quantity }}</span
      >
      <span title="zatwierdzone opinie do produktu" v-if="reviews.length">
        <router-link
          :to="{
            name: 'reviewsForItem',
            params: { itemId: modalProdctDetails.id },
          }"
          >{{ reviews.length }}
          <i class="material-icons">comment</i></router-link
        >
      </span>
      <span title="Historia sprzedaży">
        <router-link
          :to="{
            name: 'reportOrdersByProductOverTime',
            params: { initialItemId: modalProdctDetails.id },
          }"
          ><i class="material-icons">insert_chart</i>Historia sprzedaży
        </router-link>
      </span>
    </div>
    <div class="modal-footer"></div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import M from "materialize-css";

import axios from "axios";
import EventBus from "@/event-bus";

export default {
  components: {},

  data() {
    return {
      modalInstance: null,
      modalProdctDetails: null,
      reviews: [],

      env: process.env,
    };
  },

  methods: {
    //modal produktu
    revealModalProdctDetailsQuickView: function (itemId) {
      console.debug("revealModalProdctDetailsQuickView", itemId);
      this.modalInstance.open();

      axios
        .get(process.env.VUE_APP_API + "/ui/item.json", {
          params: {
            id: itemId,
            ref: "admin:revealModalProdctDetailsQuickView",
          },
        })
        .then((axiosResponse) => {
          this.modalProdctDetails = axiosResponse.data.item;
          console.debug("axiosResponse ok ");
        })
        .catch((error) => {
          console.error("ModalProdctDetailsQuickView error: ", error);
        });

      firebase
        .firestore()
        .collection("reviews")
        .doc(itemId)
        .get()
        .then((doc) => {
          this.reviews = doc.data()
            ? doc.data().reviews.sort((a, b) => {
                return b.createDate - a.createDate;
              })
            : [];
          console.log("tr", this.reviews);
        });
    },
  },

  created() {
    console.log("created");
  },

  mounted() {
    //modal produktu
    this.modalInstance = M.Modal.init(
      document.querySelector("#modalProdctDetailQuickView"),
      {
        onCloseEnd: () => {
          console.log("modalProdctDetailQuickView closed");
          (this.modalProdctDetails = null), (this.reviews = []);
        },
      }
    );

    EventBus.$on("OPEN_PRODUCT_QUICK_VIEW", (payload) => {
      console.log("on OPEN_PRODUCT_QUICK_VIEW", payload);
      this.revealModalProdctDetailsQuickView(payload.itemId);
    });
  },

  beforeDestroy() {
    console.log("beforeDestroy");
    EventBus.$off("OPEN_PRODUCT_QUICK_VIEW");

    this.modalInstance.close();
    this.modalInstance.destroy();
  },
};
</script>

<style scoped>
div.modal-content > span {
  margin-right: 20px;
}
</style>
