<template>
  <div>
    <h3>Aktualizacja stanów i cen - przegląd zmian</h3>
    <div class="card-panel grey-text text-darken-1">
       
      <h2>Stany i ceny</h2>
      Pokazuj: 
      <button @click="showSelection='REMOVED'" class="waves-effect waves-light btn red darken-2">Usunięte {{uploadDiff ? `(${uploadDiff.removed})` : null }}</button>
      <button @click="showSelection='ADDED'" class="waves-effect waves-light btn">Dodane {{uploadDiff ? `(${uploadDiff.added})` : null }}</button>
      <button @click="showSelection='MODIFIED'" class="waves-effect waves-light btn orange amber">Zmienione {{uploadDiff ? `(${uploadDiff.modified})` : null }}</button>
      <button @click="showSelection='UNCHANGED'" class="waves-effect waves-light btn-flat ">Bez zmian {{uploadDiff ? `(${uploadDiff.unmodified})` : null }}</button>
      <button @click="showSelection='ALL'" class="waves-effect waves-light btn-large">Wszystkie</button>

      <ul class="collection" style="list-style: none;">
        <li v-for="stockDiff in uploadDiff.stocksDiff" :key="stockDiff.id" v-bind:class="'collection-item diff'+stockDiff.operation" v-show="shouldShow(stockDiff.operation)">
          <del v-if="stockDiff.operation == 'REMOVED'">{{stockDiff.id}} : {{stockDiff.text}}</del>
          <ins v-else-if="stockDiff.operation == 'ADDED'">{{stockDiff.id}} : {{stockDiff.text}}</ins>
          <span v-else-if="stockDiff.operation == 'MODIFIED'">{{stockDiff.id}} : 
            <template v-for="change in stockDiff.changes">
              <del v-if="change.operation == 'DELETE'" :key="change.text+change.operation">{{change.text}}</del>
              <ins v-else-if="change.operation=='INSERT'" :key="change.text+change.operation">{{change.text}}</ins>
              <span v-else :key="change.text+change.operation">{{change.text}}</span>
            </template>
          </span>
          <span v-else>{{stockDiff.id}} : {{stockDiff.text}}</span>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    uploadDiff: Object
  },

  data() {
    return {
      showSelection: "ALL"
    };
  },

  methods: {
    shouldShow(operation) {
      return this.showSelection == "ALL" || operation == this.showSelection;
    }
  }
};
</script>

<style scoped>
ins {
  background-color: rgb(230, 255, 230);
  text-decoration: none;
}

del {
  background-color: rgb(255, 230, 230);
}

.btn, .btn-large{
  margin-left: 20px;
}
</style>
