var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal bottom-sheet",attrs:{"id":"modalProdctDetailQuickView"}},[(_vm.modalProdctDetails)?_c('div',{staticClass:"modal-content"},[_c('span',[_vm._v(" id: "+_vm._s(_vm.modalProdctDetails.id)+" "),_c('a',{attrs:{"href":`${_vm.env.VUE_APP_UI}${
          _vm.env.VUE_APP_EXT_LINK_PRODUCT
            ? require('printf')(
                _vm.env.VUE_APP_EXT_LINK_PRODUCT,
                _vm.modalProdctDetails.prettyId
              )
            : '/i_' + _vm.modalProdctDetails.prettyId
        }`,"target":"_new"}},[_c('i',{staticClass:"material-icons"},[_vm._v("launch")])])]),(
        _vm.modalProdctDetails.imgsBySize && _vm.modalProdctDetails.imgsBySize['150']
      )?_c('img',{staticStyle:{"float":"right"},attrs:{"src":`${_vm.env.VUE_APP_API}/ii/${
        _vm.modalProdctDetails.id
      }/150/${_vm.modalProdctDetails.imgsBySize['150'].slice(0, 1)}`}}):_vm._e(),_c('h4',[_vm._v(_vm._s(_vm.modalProdctDetails.name))]),(_vm.modalProdctDetails.stock && _vm.modalProdctDetails.stock.price)?_c('h5',[_vm._v(" "+_vm._s(_vm.$n(_vm.modalProdctDetails.stock.price, "currency"))+" ")]):_vm._e(),(_vm.modalProdctDetails.stock && _vm.modalProdctDetails.stock.quantity)?_c('span',[_vm._v(" Stock: "+_vm._s(_vm.modalProdctDetails.stock.quantity))]):_vm._e(),(_vm.reviews.length)?_c('span',{attrs:{"title":"zatwierdzone opinie do produktu"}},[_c('router-link',{attrs:{"to":{
          name: 'reviewsForItem',
          params: { itemId: _vm.modalProdctDetails.id },
        }}},[_vm._v(_vm._s(_vm.reviews.length)+" "),_c('i',{staticClass:"material-icons"},[_vm._v("comment")])])],1):_vm._e(),_c('span',{attrs:{"title":"Historia sprzedaży"}},[_c('router-link',{attrs:{"to":{
          name: 'reportOrdersByProductOverTime',
          params: { initialItemId: _vm.modalProdctDetails.id },
        }}},[_c('i',{staticClass:"material-icons"},[_vm._v("insert_chart")]),_vm._v("Historia sprzedaży ")])],1)]):_vm._e(),_c('div',{staticClass:"modal-footer"})])
}
var staticRenderFns = []

export { render, staticRenderFns }